//const debugStyles = false;

// export const colors = {
//   primary: "#792980",
//   primaryLight: "#aa58b0",
//   primaryDark: "#4a0053",

//   secondary: "#e4126b",
//   secondaryLight: "#ff5b99",
//   secondaryDark: "#ac0041",

//   muted: "#E0E0E0",
//   success: "#00C853",
//   warning: "#FF6D00",
//   danger: "#D50000",

//   transparent: "transparent",
//   white: "#FFFFFF",
//   black: "#000000",
//   almostWhite: "#f2f2f2",
//   almostBlack: "#2c2c2c", //probar 1e1e1e
// };

export const fontStyles = {
  menuItem: { fontSize: 10, fontWeight: "400", textTransform: "uppercase" },
  sectionTitle: { fontSize: 14, fontWeight: "600", textTransform: "uppercase" },
};

export const colors = {
  primary: "#792980",
  primaryLight: "#aa58b0",
  primaryDark: "#4a0053",

  secondary: "#e4126b",
  secondaryLight: "#ff5b99",
  secondaryDark: "#ac0041",

  muted: "#E0E0E0",
  success: "#00C853",
  warning: "#FF6D00",
  danger: "#D50000",

  background: "94, 163, 179",
  bg: {
    100: "#ffffff",
    200: "#f6f6f6",
    300: "#eeeeee",
    400: "#cccccc",
    500: "#888888",
    600: "#666666",
    700: "#444444",
    800: "#222222",
    900: "#000000",
  },
};

export const mainStyles = {
  container: { backgroundColor: "red" },
  boxRadius: {
    borderRadius: 5,
  },
  sectionTitle: {
    color: "red",
  },
};
