import React, { Component } from "react";
import axios from "axios";
import {
  parseURL,
  appId,
  rowsPerPage,
  typeForTask,
} from "../resources/variables";
import {
  PlayCircleFilled,
  Cancel,
  MoreHoriz,
  Search,
} from "@material-ui/icons";
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  Divider,
  FormGroup,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core/";

export class Tasks extends Component {
  state = {
    isThinking: true,
    tasks: null,
    taskCategory: null,
    arrayHolderForSearchByKeyword: null,
    page: 0,
    rowsPerPage: 10,
    openDialog: false,
    alertDialog: false,
    menuOpen: false,
    anchorEl: undefined,
    keyword: "",
    pushOrPut: "put",
    currentAction: "editTask",
    // To edit or clone
    currentTaskObjectId: null,
    taskCatObjectId: null,
    requieresProof: false,
    healthKit: false,
    titleTaskEn: "",
    titleTaskEs: "",
    shortTitleEn: "",
    shortTitleEs: "",
    externalLinkEn: "",
    externalLinkEs: "",
    instructionsEn: "",
    instructionsEs: "",
    hkInstructionsEn: "",
    hkInstructionsEs: "",
    goal: 0,
    type: null,
    unitEn: "",
    unitEs: "",
    multiplier: 0,
    alternativeTasks: [],
    // To edit or clone
    altBuilder_Tasks: [[], [], []],
    altBuilder_SelectedCat: [],
    localAlternativeTasks: localAlternativeTasks,
  };

  componentDidMount() {
    this.arrayHolderNeverChange = [];
    this.arrayholderForSearchByCategory = [];
    this.arrayHolderAlternativeTask = [];
    this.getTasks();
    this.getTaskCategory();
  }

  getTasks() {
    axios
      .get(parseURL + "classes/Task?include=category&order=-createdAt&limit=1000", {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        this.setState({
          tasks: res.data.results,
          arrayHolderForSearchByKeyword: res.data.results,
        });
        this.arrayHolderNeverChange = res.data.results;
        this.arrayholderForSearchByCategory = res.data.results;
        this.arrayHolderAlternativeTask = res.data.results;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getTaskCategory() {
    axios
      .get(parseURL + "classes/TaskCategory", {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        this.setState({ taskCategory: res.data.results });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  assignAlternativeTaskToLocalAlternativeTask() {
    let temp = this.state.localAlternativeTasks;
    for (let i = 0; i < this.state.alternativeTasks.length; i++) {
      temp[i] = this.state.alternativeTasks[i];
    }
    this.setState(
      {
        localAlternativeTasks: temp,
        altBuilder_Tasks: [[], [], []],
        altBuilder_SelectedCat: [],
        openDialog: true,
      },
      () => this.renderAltTaskCat()
    );
  }

  renderAltTaskCat() {
    let temp = [];
    this.state.localAlternativeTasks.forEach((altTask, index) => {
      if (altTask !== null) {
        let task = this.arrayHolderNeverChange.find(
          (obj) => obj.objectId === altTask
        );
        let taskCat = this.state.taskCategory.find(
          (obj) => obj.objectId === task.category.objectId
        );
        temp.push(taskCat.title.en);
        this.searchFilterAlternativeTask(taskCat.title.en, index);
      }
    });
    this.setState({ altBuilder_SelectedCat: temp });
  }

  searchFilterAlternativeTask(category, index) {
    console.log(category);
    let newData = this.arrayHolderAlternativeTask.filter(
      (element) => element.category.title.en === category
    );
    console.log(newData);
    let temp = this.state.altBuilder_Tasks;
    temp[index] = newData;

    let temp2 = this.state.altBuilder_SelectedCat;
    temp2[index] = category;

    this.setState(
      { altBuilder_Tasks: temp, altBuilder_SelectedCat: temp2 },
      () =>
        console.log(
          this.state.altBuilder_SelectedCat,
          this.state.altBuilder_Tasks
        )
    );
  }

  changeAlternativeTaskCat(objectId, index) {
    let temp = this.state.localAlternativeTasks;
    temp[index] = objectId;
    this.setState({ localAlternativeTasks: temp });
    //this.state.localAlternativeTasks[index] = objectId;
  }

  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(e) {
    let numberValue = Number(e);
    this.setState({ rowsPerPage: numberValue, page: 0 });
  }

  searchFilter(text) {
    this.setState({ keyword: text });
    let newData = this.state.arrayHolderForSearchByKeyword.filter((element) => {
      let itemData = `${element.title.en.toUpperCase()}`;
      let textData = text.toUpperCase();
      itemData = itemData
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trimRight();
      textData = textData
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trimRight();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({ tasks: newData });
  }

  searchFilterByCategory(text) {
    if (text === "viewAll") {
      this.setState({
        tasks: this.arrayHolderNeverChange,
        arrayHolderForSearchByKeyword: this.arrayHolderNeverChange,
      });
    } else {
      let newData = this.arrayholderForSearchByCategory.filter((element) => {
        let itemData = `${element.category.title.en.toUpperCase()}`;
        let textData = text.toUpperCase();
        itemData = itemData
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trimRight();
        textData = textData
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trimRight();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({
        tasks: newData,
        arrayHolderForSearchByKeyword: newData,
        keyword: "",
      });
    }
  }

  renderDialog() {
    return (
      this.state.tasks !== null &&
      this.state.taskCategory !== null && (
        <DialogContent>
          <TextField
            fullWidth
            select
            variant="outlined"
            size="small"
            label="Category"
            defaultValue={
              this.state.currentAction === "editTask"
                ? this.state.taskCatObjectId
                : ""
            }
            onChange={(e) => this.setState({ taskCatObjectId: e.target.value })}
          >
            {this.state.taskCategory.map((e) => (
              <MenuItem key={e.objectId} value={e.objectId}>
                {e.title.en}
              </MenuItem>
            ))}
          </TextField>
          <Divider style={{ height: 20, backgroundColor: "transparent" }} />
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.requieresProof}
                  onChange={(e) =>
                    this.setState({ requieresProof: e.target.checked })
                  }
                  size="small"
                />
              }
              label={<Typography>Requires proof</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.healthKit}
                  onChange={(e) =>
                    this.setState({ healthKit: e.target.checked })
                  }
                  size="small"
                />
              }
              label={<Typography>Health Kit</Typography>}
            />
          </FormGroup>
          <Divider style={{ height: 20, backgroundColor: "transparent" }} />
          <Box display="flex" justifyContent="space-between">
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Title 🇺🇸"
              defaultValue={this.state.titleTaskEn}
              onChange={(e) => this.setState({ titleTaskEn: e.target.value })}
            />
            <Divider style={{ width: 10, backgroundColor: "transparent" }} />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Title 🇲🇽"
              defaultValue={this.state.titleTaskEs}
              onChange={(e) => this.setState({ titleTaskEs: e.target.value })}
            />
          </Box>
          <Divider style={{ height: 20, backgroundColor: "transparent" }} />
          <Box display="flex" justifyContent="space-between">
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Short title 🇺🇸"
              defaultValue={this.state.shortTitleEn}
              onChange={(e) => this.setState({ shortTitleEn: e.target.value })}
            />
            <Divider style={{ width: 10, backgroundColor: "transparent" }} />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Short title 🇲🇽"
              defaultValue={this.state.shortTitleEs}
              onChange={(e) => this.setState({ shortTitleEs: e.target.value })}
            />
          </Box>
          <Divider style={{ height: 20, backgroundColor: "transparent" }} />
          <Box display="flex" justifyContent="space-between">
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="External Link 🇺🇸"
              defaultValue={this.state.externalLinkEn}
              onChange={(e) =>
                this.setState({ externalLinkEn: e.target.value })
              }
            />
            <Divider style={{ width: 10, backgroundColor: "transparent" }} />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="External Link 🇲🇽"
              defaultValue={this.state.externalLinkEs}
              onChange={(e) =>
                this.setState({ externalLinkEs: e.target.value })
              }
            />
          </Box>
          <Divider style={{ height: 20, backgroundColor: "transparent" }} />
          <Box display="flex" justifyContent="space-between">
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              size="small"
              label="Instructions 🇺🇸"
              defaultValue={this.state.instructionsEn}
              onChange={(e) =>
                this.setState({ instructionsEn: e.target.value })
              }
            />
            <Divider style={{ width: 10, backgroundColor: "transparent" }} />
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              size="small"
              label="Instructions 🇲🇽"
              defaultValue={this.state.instructionsEs}
              onChange={(e) =>
                this.setState({ instructionsEs: e.target.value })
              }
            />
          </Box>
          <Divider style={{ height: 20, backgroundColor: "transparent" }} />
          {this.state.healthKit === true && (
            <Box>
              <Divider style={{ height: 20, backgroundColor: "transparent" }} />
              <Box display="flex" justifyContent="space-between">
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  size="small"
                  label="Health kit instructions 🇺🇸"
                  defaultValue={this.state.hkInstructionsEn}
                  onChange={(e) =>
                    this.setState({ hkInstructionsEn: e.target.value })
                  }
                />
                <Divider
                  style={{ width: 10, backgroundColor: "transparent" }}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  size="small"
                  label="Health kit instructions 🇲🇽"
                  defaultValue={this.state.hkInstructionsEs}
                  onChange={(e) =>
                    this.setState({ hkInstructionsEs: e.target.value })
                  }
                />
              </Box>
              <Divider style={{ height: 20, backgroundColor: "transparent" }} />
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                size="small"
                label="Goal"
                defaultValue={this.state.goal}
                onChange={(e) => this.setState({ goal: e.target.value })}
              />
              <Divider style={{ height: 20, backgroundColor: "transparent" }} />
              <TextField
                fullWidth
                select
                variant="outlined"
                size="small"
                label="Type"
                defaultValue={this.state.type}
                onChange={(e) => this.setState({ type: e.target.value })}
              >
                {typeForTask.map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </TextField>
              <Divider style={{ height: 20, backgroundColor: "transparent" }} />
              <Box display="flex" justifyContent="space-between">
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Unit 🇺🇸"
                  defaultValue={this.state.unitEs}
                  onChange={(e) => this.setState({ unitEs: e.target.value })}
                />
                <Divider
                  style={{ width: 10, backgroundColor: "transparent" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Unit 🇲🇽"
                  defaultValue={this.state.unitEn}
                  onChange={(e) => this.setState({ unitEn: e.target.value })}
                />
              </Box>
              <Divider style={{ height: 20, backgroundColor: "transparent" }} />
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                size="small"
                label="Multiplier"
                defaultValue={this.state.multiplier}
                onChange={(e) => this.setState({ multiplier: e.target.value })}
              />
              <Divider style={{ height: 20, backgroundColor: "transparent" }} />
            </Box>
          )}
          <Divider style={{ height: 20, backgroundColor: "transparent" }} />
          {this.state.localAlternativeTasks.map((e, i) => (
            <Box display="flex" justifyContent="space-between" key={i}>
              <TextField
                fullWidth
                select
                variant="outlined"
                size="small"
                label="Category"
                style={{ marginBottom: 10 }}
                value={this.state.altBuilder_SelectedCat[i]}
                onChange={(e) =>
                  this.searchFilterAlternativeTask(e.target.value, i)
                }
              >
                {this.state.taskCategory.map((e, i) => (
                  <MenuItem key={e.objectId} value={e.title.en} selected={true}>
                    {e.title.en}
                  </MenuItem>
                ))}
              </TextField>
              <Divider style={{ width: 10, backgroundColor: "transparent" }} />
              <TextField
                fullWidth
                select
                variant="outlined"
                size="small"
                label="Type"
                style={{ marginBottom: 10 }}
                value={e}
                onChange={(e) =>
                  this.changeAlternativeTaskCat(e.target.value, i)
                }
              >
                {this.state.altBuilder_Tasks[i].map((e) => (
                  <MenuItem key={e.objectId} value={e.objectId}>
                    {e.title.en}
                  </MenuItem>
                ))}
              </TextField>
              <IconButton onClick={() => this.deleteAlternativeTask(e, i)}>
                <Cancel />
              </IconButton>
            </Box>
          ))}
        </DialogContent>
      )
    );
  }

  deleteAlternativeTask(objectId, i) {
    let temp = this.state.localAlternativeTasks;
    temp[i] = null;
    this.setState({ localAlternativeTasks: temp });
    let temp2 = this.state.altBuilder_SelectedCat;
    temp2[i] = "";
    this.setState({ altBuilder_SelectedCat: temp2 }, () =>
      console.log(this.state.localAlternativeTasks)
    );
  }

  createObjectToPushorPut() {
    let tempAltTask = [];
    for (let i = 0; i < this.state.localAlternativeTasks.length; i++) {
      if (this.state.localAlternativeTasks[i] !== null) {
        tempAltTask.push(this.state.localAlternativeTasks[i]);
      }
    }
    let params = {
      category: {
        __type: "Pointer",
        className: "TaskCategory",
        objectId: this.state.taskCatObjectId,
      },
      requieresProof: this.state.requieresProof,
      healthKit: this.state.healthKit,
      title: {
        en:
          this.state.pushOrPut === "put"
            ? this.state.titleTaskEn
            : `${this.state.titleTaskEn} (copy)`,
        es:
          this.state.pushOrPut === "put"
            ? this.state.titleTaskEs
            : `${this.state.titleTaskEs} (copy)`,
      },
      titleShort: {
        en: this.state.shortTitleEn,
        es: this.state.shortTitleEs,
      },
      externalLink: {
        en: this.state.externalLinkEn,
        es: this.state.externalLinkEs,
      },
      instructions: {
        en: this.state.instructionsEn,
        es: this.state.instructionsEs,
      },
      hkInstructions: {
        en: this.state.instructionsEn,
        es: this.state.instructionsEs,
      },
      hkDetails: {
        goal: this.state.goal,
        type: this.state.type,
        unit: {
          en: this.state.unitEn,
          es: this.state.unitEs,
        },
        multiplier: this.state.multiplier,
        progress: 0,
      },
      alternativeTasks: tempAltTask,
    };
    if (this.state.pushOrPut === "put") {
      this.putTask(params);
    } else {
      this.cloneTask(params);
    }
  }

  newTask() {
    for (let i = 0; i < this.state.alternativeTasks.length; i++) {
      let temp = this.state.localAlternativeTasks
      temp[i] = null;
      this.setState({localAlternativeTasks: temp})
      //this.state.localAlternativeTasks[i] = null;
    }
    this.setState(
      {
        currentAction: "newTask",
        currentTaskObjectId: null,
        taskCatObjectId: null,
        requieresProof: false,
        healthKit: false,
        titleTaskEn: "",
        titleTaskEs: "",
        shortTitleEn: "",
        shortTitleEs: "",
        externalLinkEn: "",
        externalLinkEs: "",
        instructionsEn: "",
        instructionsEs: "",
        hkInstructionsEn: "",
        hkInstructionsEs: "",
        goal: 0,
        type: null,
        unitEn: "",
        unitEs: "",
        multiplier: 0,
        alternativeTasks: [null, null, null],
        altBuilder_Tasks: [[], [], []],
        altBuilder_SelectedCat: [],
        openDialog: true,
      },
      () => this.assignAlternativeTaskToLocalAlternativeTask()
    );
  }

  objectToNewTask() {
    let tempAltTask = [];
    for (let i = 0; i < this.state.localAlternativeTasks.length; i++) {
      if (this.state.localAlternativeTasks[i] !== null) {
        tempAltTask.push(this.state.localAlternativeTasks[i]);
      }
    }
    let params = {
      category: {
        __type: "Pointer",
        className: "TaskCategory",
        objectId: this.state.taskCatObjectId,
      },
      requieresProof: this.state.requieresProof,
      healthKit: this.state.healthKit,
      title: {
        en: this.state.titleTaskEn,
        es: this.state.titleTaskEs,
      },
      titleShort: {
        en: this.state.shortTitleEn,
        es: this.state.shortTitleEs,
      },
      externalLink: {
        en: this.state.externalLinkEn,
        es: this.state.externalLinkEs,
      },
      instructions: {
        en: this.state.instructionsEn,
        es: this.state.instructionsEs,
      },
      hkInstructions: {
        en: this.state.instructionsEn,
        es: this.state.instructionsEs,
      },
      hkDetails: {
        goal: this.state.goal,
        type: this.state.type,
        unit: {
          en: this.state.unitEn,
          es: this.state.unitEs,
        },
        multiplier: this.state.multiplier,
        progress: 0,
      },
      alternativeTasks: tempAltTask,
    };
    this.postNewTask(params);
  }

  postNewTask(params) {
    axios
      .post(parseURL + "classes/Task", params, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ openDialog: false });
        this.getTasks();
      });
  }

  putTask(params) {
    axios
      .put(
        parseURL + "classes/Task/" + this.state.currentTaskObjectId,
        params,
        {
          headers: {
            "X-Parse-Application-Id": appId,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ openDialog: false, menuOpen: false });
        this.getTasks();
      });
  }

  cloneTask(params) {
    axios
      .post(parseURL + "classes/Task", params, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ openDialog: false, menuOpen: false });
        this.getTasks();
      });
  }

  deleteTask() {
    axios
      .delete(parseURL + "classes/Task/" + this.state.currentTaskObjectId, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({
          openDialog: false,
          menuOpen: false,
          alertDialog: false,
        });
        this.getTasks();
      });
  }

  render() {
    return (
      <Box style={{ paddingLeft: 90 }}>
        <Box
          display="flex"
          justifyContent="space-around"
          style={{ backgroundColor: "blue", padding: 10 }}
        >
          <Button
            variant="contained"
            onClick={() => this.props.history.push("/dashboard")}
          >
            Atras
          </Button>
          <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
          <Button variant="contained" onClick={() => this.newTask()}>
            Add Task
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-around">
          <Typography
            style={{
              fontWeight: "600",
              fontSize: 12,
              textTransform: "uppercase",
              marginLeft: 10,
              marginTop: 10,
            }}
          >
            Tasks
          </Typography>
          <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
          {this.state.tasks !== null && this.state.taskCategory !== null && (
            <Box display="flex" style={{ marginTop: 10, marginRight: 10 }}>
              <TextField
                fullWidth
                select
                size="small"
                style={{ width: 160 }}
                variant="outlined"
                label="Category"
                defaultValue={"viewAll"}
                onChange={(e) => this.searchFilterByCategory(e.target.value)}
              >
                {["viewAll", ...this.state.taskCategory].map((e, i) =>
                  i === 0 ? (
                    <MenuItem key={0} value={"viewAll"}>
                      View All
                    </MenuItem>
                  ) : (
                    <MenuItem key={i} value={e.title.en}>
                      {e.title.en}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Box>
          )}
          <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
          <TextField
            style={{ marginTop: 10, marginRight: 10 }}
            size="small"
            variant="outlined"
            label="Keyword"
            value={this.state.keyword}
            onChange={(text) => this.searchFilter(text.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {this.state.tasks !== null && (
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell> Alternative Tasks</TableCell>
                  <TableCell> Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tasks
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((item, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {item.category.title.en}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.title.en}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.alternativeTasks === undefined
                          ? "0"
                          : item.alternativeTasks.length}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <IconButton
                          onClick={() =>
                            this.setState(
                              {
                                currentTaskObjectId: item.objectId,
                                taskCatObjectId: item.category.objectId,
                                requieresProof: item.requieresProof,
                                healthKit: item.healthKit,
                                titleTaskEn: item.title.en,
                                titleTaskEs: item.title.es,
                                shortTitleEn: item.titleShort.en,
                                shortTitleEs: item.titleShort.es,
                                externalLinkEn: item.externalLink.en,
                                externalLinkEs: item.externalLink.es,
                                instructionsEn: item.instructions.en,
                                instructionsEs: item.instructions.es,
                                hkInstructionsEn:
                                  item.hkInstructions === undefined
                                    ? null
                                    : item.hkInstructions.en,
                                hkInstructionsEs:
                                  item.hkInstructions === undefined
                                    ? null
                                    : item.hkInstructions.es,
                                goal:
                                  item.hkDetails === undefined
                                    ? null
                                    : item.hkDetails.goal,
                                type:
                                  item.hkDetails === undefined
                                    ? null
                                    : item.hkDetails.type,
                                unitEn:
                                  item.hkDetails === undefined
                                    ? null
                                    : item.hkDetails.unit.en,
                                unitEs:
                                  item.hkDetails === undefined
                                    ? null
                                    : item.hkDetails.unit.es,
                                multiplier:
                                  item.hkDetails === undefined
                                    ? null
                                    : item.hkDetails.multiplier,
                                alternativeTasks:
                                  item.alternativeTasks === undefined
                                    ? [null, null, null]
                                    : item.alternativeTasks,
                                localAlternativeTasks: [null, null, null],
                                currentAction: "editTask",
                              },
                              () =>
                                this.assignAlternativeTaskToLocalAlternativeTask()
                            )
                          }
                        >
                          <PlayCircleFilled />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={rowsPerPage}
              component="div"
              count={this.state.tasks.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={(e, n) => this.handleChangePage(e, n)}
              onChangeRowsPerPage={(e) =>
                this.handleChangeRowsPerPage(e.target.value)
              }
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            />
          </TableContainer>
        )}
        <Dialog open={this.state.openDialog} fullWidth={true}>
          <DialogTitle
            disableTypography
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Task
            <IconButton onClick={() => this.setState({ openDialog: false })}>
              <Cancel />
            </IconButton>
          </DialogTitle>
          {this.renderDialog()}
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Button
              disabled={this.state.currentAction === "newTask" ? true : false}
              onClick={(e) =>
                this.setState({
                  menuOpen: true,
                  anchorEl: e.currentTarget,
                })
              }
            >
              <MoreHoriz />
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                this.setState({ pushOrPut: "put" }, () =>
                  this.state.currentAction === "editTask"
                    ? this.createObjectToPushorPut()
                    : this.objectToNewTask()
                )
              }
            >
              Accept
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
          anchorEl={this.state.anchorEl}
          getContentAnchorEl={null}
          open={this.state.menuOpen}
          onClose={() => this.setState({ menuOpen: false })}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MenuItem
            onClick={() =>
              this.setState({ pushOrPut: "post" }, () =>
                this.createObjectToPushorPut()
              )
            }
          >
            Clone
          </MenuItem>
          <MenuItem
            onClick={() => this.setState({ alertDialog: true })}
            style={{ color: "red" }}
          >
            Delete
          </MenuItem>
        </Menu>
        <Dialog open={this.state.alertDialog} fullWidth={true}>
          <DialogTitle>Alert</DialogTitle>
          <DialogContent>
            <Typography>Are you sure to delete it</Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Button onClick={() => this.setState({ alertDialog: false })}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => this.deleteTask()}>
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default Tasks;

const localAlternativeTasks = [null, null, null];
