import React, { Component } from "react";
import { parseURL, appId, masterKey } from "../resources/variables";
import axios from "axios";
import moment from "moment";
import Ikon from "../resources/icon";
import Nestable from "react-nestable";
import { colors, mainStyles } from "../styles/styles";
import {
  Eco,
  Search,
  Cancel,
  DragIndicator,
  Edit,
  MoreHoriz,
  Lock,
  NewReleases,
  LocalHospital,
  Person,
  Assignment,
} from "@material-ui/icons";
import {
  Alert,
  AvatarGroup,
  SpeedDial,
  SpeedDialAction,
} from "@material-ui/lab";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
  ButtonGroup,
  MenuItem,
  Menu,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Switch,
  Snackbar,
} from "@material-ui/core/";

import { MedicalHistory } from "../components/MedicalHistory";
import { UserDetails } from "../components/UserDetails";
import { NewDiet } from "../components/NewDiet";
import { renderMealTime } from "../resources/functions";
import {
  dietStructure_3,
  dietStructure_7,
  group,
  collection,
  item,
} from "../resources/skeletons";

let inputRef = React.createRef();

export class Results extends Component {
  state = {
    isThinking: true,
    user: null,
    dietHistory: null,
    dishes: null,
    selectedMealOrMH: 0,
    keyword: "",
    dishLimit: 10,
    diet: null,
    dietTitle: null,
    dietIndex: 0,
    menuOpen: false,
    menuOpen2: false,
    anchorEl: undefined,
    anchorEl2: undefined,
    dialogNewCollection: false,
    openDialog: false, //Boolean para abrir el Dialog
    activeDialog: "dietTitle", // dietTitle, newCollection, editCollection, newDish, editDish
    currentCollection: null, // Index del arreglo a editar
    currentItem: 0, // Index del platillo a editar
    // Dish Elements
    details: null,
    isPortion: false,
    hasRecipe: false,
    dairy: 0,
    legumes: 0,
    calorieFree: 0,
    oilsAndFats: 0,
    protein: 0,
    cereals: 0,
    fruits: 0,
    vegetables: 0,
    //
    dialogAlert: false,
    objIdToEdit: "",
    isEdit: false,
    snack: false,
    dialogAlertLoad: false,
    //
    dietDialog: false,
    viewDietIndex: 0,
    dialogTitleAlert: "",
    dietTemp: null,
    objIdToEditTemp: null,
    dietTitleTemp: "",
    selectedMeal: null,
    clone: false,
    openSpeedDial: false,
    speedDialId: null,
    dialogSpeedDial: false,
    userNote: "",
    //
    dietObjectId: null,
    dialogAlertDeleteDiet: false,
    //
    snackForConversation: false,
    disabledConversation: true,
    //
    dialogPassword: false,
    snackPassword: false,
    newUser: "",
    newPassword: "",
    dialogSortFood: false,
    dialogMealTime: false,
    //
  };

  componentDidMount() {
    this.arrayholder = [];
    this.arrayHolderMealTime = [];
    this.array = [];
    const params = this.props.match.params;
    let dietId = localStorage.getItem("dietId");
    if (dietId) {
      this.setState({ isEdit: true, dietObjectId: dietId });
      this.getSpecificDiet(dietId);
    }
    console.log(params.id);
    this.getUser(params.id);
    this.getDiet(params.id);
    this.getMeals();
    this.validateConversation();
  }

  getUser(objectId) {
    axios
      .get(parseURL + "users/" + objectId, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({ user: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getDiet(objectId) {
    let constraints = JSON.stringify({
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: objectId,
      },
    });
    axios
      .get(parseURL + "classes/Diet/?where=" + constraints, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        this.setState({
          dietHistory: res.data.results,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getMeals() {
    axios
      .get(parseURL + "classes/Dish?limit=1000", {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        this.setState({ dishes: res.data.results });
        this.arrayholder = res.data.results;
        this.arrayHolderMealTime = res.data.results;
        this.array = res.data.results;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  postDish() {
    this.setState({ menuOpen2: false });
    let newDish = JSON.parse(JSON.stringify(item));
    newDish.name = inputRef.current.value;
    newDish.details = this.state.details;
    newDish.portions.dairy.value = this.state.dairy;
    newDish.portions.legumes.value = this.state.legumes;
    newDish.portions.calorieFree.value = this.state.calorieFree;
    newDish.portions.oilsAndFats.value = this.state.oilsAndFats;
    newDish.portions.protein.value = this.state.protein;
    newDish.portions.cereals.value = this.state.cereals;
    newDish.portions.fruits.value = this.state.fruits;
    newDish.portions.vegetables.value = this.state.vegetables;
    if (inputRef.current.value !== "" || inputRef.current.value !== " ") {
      axios
        .post(
          parseURL + "classes/Dish",
          {
            name: newDish.name,
            details: newDish.details,
            portions: newDish.portions,
            calories: 0,
          },
          {
            headers: {
              "X-Parse-Application-Id": appId,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.getMeals();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  getSpecificDiet(dietId) {
    axios
      .get(parseURL + "classes/Diet/" + dietId, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.diet);
        this.holderDiet = [];
        this.setState({
          diet: res.data.diet,
          objIdToEdit: dietId,
          dietTitle: res.data.title,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        localStorage.removeItem("dietId");
      });
  }

  editDiet() {
    let params = {
      diet: this.state.diet,
      title: this.state.dietTitle,
    };
    axios
      .put(parseURL + "classes/Diet/" + this.state.objIdToEdit, params, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res);
        this.setState({ snack: true });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  pushDiet() {
    const objectId = this.props.match.params.id;
    let params = {
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: objectId,
      },
      diet: this.state.diet,
      title: this.state.dietTitle,
    };
    axios
      .post(parseURL + "classes/Diet", params, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.updateDietStatus(objectId);
        this.setState({
          objIdToEdit: res.data.objectId,
          isEdit: true,
          snack: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  updateDietStatus(userId) {
    console.log(this.state.user);
    let params =
      this.state.user.subscription == "monthly"
        ? { dietStatus: 3 }
        : {
            dietStatus: 3,
            isPremium: true,
            subscription: "manual",
            purchaseDate: {
              __type: "Date",
              iso: moment().utc().format(),
            },
            expiresAt: {
              __type: "Date",
              iso: moment().add(30, "days").utc().format(),
            },
          };
    axios
      .put(parseURL + "users/" + userId, params, {
        headers: {
          "X-Parse-Application-Id": appId,
          "X-Parse-Master-Key": masterKey,
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  searchFilter(text) {
    this.setState({ keyword: text });
    let newData = this.arrayholder.filter((element) => {
      //return element.item.toLowerCase().includes(text.toLowerCase());
      let itemData;
      if (!!element.ingredients && element.ingredients.length > 0) {
        itemData = `${element.name.toUpperCase()}${JSON.stringify(
          element.ingredients
        ).toUpperCase()}`;
      } else {
        itemData = `${element.name.toUpperCase()}`;
      }
      let textData = text.toUpperCase();
      itemData = itemData.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      //.trimRight();
      textData = textData.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      //.trimRight();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({ dishes: newData });
  }

  searchFilterMealTime(i) {
    let newData = this.arrayHolderMealTime.filter((element) => {
      if (element.mealTime !== undefined) {
        if (element.mealTime[i] !== undefined) {
          return element.mealTime[i].checked === true;
        }
      }
    });
    this.setState({ dishes: newData }, () => (this.arrayholder = newData));
  }

  showMore() {
    let temp = this.state.dishLimit;
    temp += 10;
    this.setState({ dishLimit: temp });
  }

  validateMovement(items) {
    let validMovement = true;
    console.log(items);

    items.forEach((collection) => {
      if (collection.type === "collection") {
        collection.children.forEach((item) => {
          if (item.type !== "item" || item.children.length !== 0) {
            validMovement = false;
          }
        });
      } else {
        validMovement = false;
      }
    });

    console.log(validMovement);

    if (validMovement) {
      let temp = this.state.diet;
      temp[this.state.dietIndex].children = items;

      this.setState({ diet: temp }, () =>
        console.log(JSON.stringify(this.state.diet))
      );
    } else {
      this.setState({ diet: this.state.diet });
    }
  }

  renderItem = ({ item, index }) => {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          overflow: "hidden",
          borderRadius: 5,
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            width: 40,
            height: 40,
            backgroundColor: `rgba( ${colors.background}, .1)`,
          }}
        >
          <DragIndicator style={{ color: "777777" }} />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={() =>
            item.type === "collection" &&
            this.setState({ currentCollection: index })
          }
          style={{
            flex: 1,
            height: 40,
            paddingLeft: 10,
            backgroundColor:
              item.type === "collection" &&
              index === this.state.currentCollection
                ? colors.primary
                : item.type === "collection"
                ? `rgba( ${colors.background}, .3)`
                : "#FFFFFF",
            color:
              item.type === "collection" &&
              index === this.state.currentCollection
                ? "#FFFFFF"
                : item.type === "collection"
                ? "rgba(30, 30, 30, 1.00)"
                : "rgba(30, 30, 30, 1.00)",
          }}
        >
          {item.type === "collection" ? (
            <Typography>{item.title}</Typography>
          ) : (
            item.type === "item" && <Typography>{item.name}</Typography>
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ width: 40, height: 40, backgroundColor: "#f0f0f0" }}
          onClick={
            item.type === "collection"
              ? () =>
                  this.setState({
                    openDialog: true,
                    activeDialog: "editCollection",
                    currentCollection: index,
                  })
              : () => this.getcurrentCollection(item, index)
          }
        >
          <Edit style={{ color: "#777777" }} />
        </Box>
      </Box>
    );
  };

  getcurrentCollection(itemToEdit, itemToEditIndex) {
    console.log(itemToEdit);
    this.setState({
      details: itemToEdit.details,
      dairy: itemToEdit.portions.dairy.value,
      legumes: itemToEdit.portions.legumes.value,
      calorieFree: itemToEdit.portions.calorieFree.value,
      oilsAndFats: itemToEdit.portions.oilsAndFats.value,
      protein: itemToEdit.portions.protein.value,
      cereals: itemToEdit.portions.cereals.value,
      fruits: itemToEdit.portions.fruits.value,
      vegetables: itemToEdit.portions.vegetables.value,
    });
    this.state.diet[this.state.dietIndex].children.forEach((element, index) => {
      element.children.forEach((item) => {
        console.log(item);
        if (item.id === itemToEdit.id) {
          this.setState({
            openDialog: true,
            activeDialog: "editDish",
            currentCollection: index,
            currentItem: itemToEditIndex,
          });
          console.log("found it", index);
        }
      });
    });
  }

  dialogAction() {
    console.log(inputRef.current.value);
    switch (this.state.activeDialog) {
      case "dietTitle":
        this.setState({ dietTitle: inputRef.current.value, openDialog: false });
        break;
      case "newCollection":
        this.addCollection(inputRef.current.value);
        this.setState({ openDialog: false });
        break;
      case "editCollection":
        this.editCollection(inputRef.current.value);
        this.setState({ openDialog: false });
        break;
      case "newDish":
        this.newDish(inputRef.current.value);
        this.setState({ openDialog: false });
        break;
      case "editDish":
        this.editDish(inputRef.current.value);
        this.setState({ openDialog: false, activeDialog: "dietTitle" });
        break;
      default:
        break;
    }
  }

  dialogActionDelete() {
    this.setState({ menuOpen2: false });
    switch (this.state.activeDialog) {
      case "editCollection":
        this.deleteCollection();
        this.setState({
          openDialog: false,
          activeDialog: "dietTile",
          dialogAlert: false,
        });
        break;
      case "editDish":
        this.deleteDish();
        this.setState({
          openDialog: false,
          activeDialog: "dietTitle",
          dialogAlert: false,
        });
        break;
      default:
        break;
    }
  }

  renderDialogTitle() {
    switch (this.state.activeDialog) {
      case "dietTitle":
        return "Diet title";
      case "newCollection":
        return "Add Category";
      case "editCollection":
        return "Edit Category";
      case "newDish":
        return "New Dish";
      case "editDish":
        return "Edit Dish";
      default:
        break;
    }
  }

  renderDialog() {
    let activeDialog = this.state.activeDialog;
    switch (activeDialog) {
      case "dietTitle":
        return (
          <DialogContent>
            <DialogContentText>
              Add a title to the diet, users will see it on their history.
            </DialogContentText>
            <TextField
              inputRef={inputRef}
              fullWidth
              defaultValue={this.state.dietTitle}
            />
          </DialogContent>
        );
      case "newCollection":
      case "editCollection":
        return (
          <DialogContent>
            <TextField
              inputRef={inputRef}
              fullWidth
              size="small"
              variant="outlined"
              label="Category Name"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={
                activeDialog === "newCollection"
                  ? null
                  : this.state.diet[this.state.dietIndex].children[
                      this.state.currentCollection
                    ].title
              }
            />
            <Typography
              style={{ color: "#777777", fontStyle: "italic", fontSize: 12 }}
            >
              Lunes, Martes, Miércoles, Opción 1, Opción 2, Diario, Sin Hambre,
              etc.
            </Typography>
          </DialogContent>
        );
      case "newDish":
      case "editDish":
        return (
          <DialogContent>
            <Divider style={{ height: 10, backgroundColor: "transparent" }} />
            <TextField
              inputRef={inputRef}
              fullWidth
              size="small"
              variant="outlined"
              label="Dish name"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={
                activeDialog === "newDish"
                  ? null
                  : this.state.diet[this.state.dietIndex].children[
                      this.state.currentCollection
                    ].children[this.state.currentItem].name
              }
            />
            <Divider style={{ height: 20, backgroundColor: "transparent" }} />
            <TextField
              //inputRef={inputRef}
              fullWidth
              size="small"
              variant="outlined"
              label="Extra details"
              multiline
              rows={4}
              value={this.state.details}
              onChange={(e) => this.setState({ details: e.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* <TextField
              accept="image/*"
              //className={classes.input}
              //style={{ display: "none" }}
              onChange={(e) => console.log(e.target.files[0])}
              id="raised-button-file"
              multiple
              type="file"
            /> */}
            <Divider style={{ height: 10, backgroundColor: "transparent" }} />
            <FormControlLabel
              control={
                <Switch
                  checked={
                    activeDialog === "newDish"
                      ? this.state.isPortion
                      : this.state.diet[this.state.dietIndex].children[
                          this.state.currentCollection
                        ].children[this.state.currentItem].isPortion
                  }
                  onChange={(e) =>
                    this.setState({ isPortion: e.target.checked })
                  }
                  name="gilad"
                  size="small"
                />
              }
              label={
                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: "500",
                    textTransform: "uppercase",
                  }}
                >
                  Show Portions
                </Typography>
              }
            />
            <Divider style={{ height: 10, backgroundColor: "transparent" }} />
            <DialogContentText style={{ fontSize: 12, fontWeight: "500" }}>
              Equivalents
            </DialogContentText>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <TextField
                size="small"
                variant="outlined"
                id="standard-number"
                label="Vegetables"
                type="number"
                value={this.state.vegetables}
                onChange={(e) => this.setState({ vegetables: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 120 }}
              />
              <TextField
                size="small"
                variant="outlined"
                id="standard-number"
                label="Cereals"
                type="number"
                value={this.state.cereals}
                onChange={(e) => this.setState({ cereals: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 120 }}
              />
              <TextField
                size="small"
                variant="outlined"
                id="standard-number"
                label="Oils and Fats"
                type="number"
                value={this.state.oilsAndFats}
                onChange={(e) => this.setState({ oilsAndFats: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 120 }}
              />
              <TextField
                size="small"
                variant="outlined"
                id="standard-number"
                label="Fruits"
                type="number"
                value={this.state.fruits}
                onChange={(e) => this.setState({ fruits: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 120 }}
              />
            </Box>
            <Divider style={{ height: 20, background: "transparent" }} />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <TextField
                size="small"
                variant="outlined"
                id="standard-number"
                label="Protein"
                type="number"
                value={this.state.protein}
                onChange={(e) => this.setState({ protein: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 120 }}
              />
              <TextField
                size="small"
                variant="outlined"
                id="standard-number"
                label="Dairy"
                type="number"
                value={this.state.dairy}
                onChange={(e) => this.setState({ dairy: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 120 }}
              />
              <TextField
                size="small"
                variant="outlined"
                id="standard-number"
                label="Legumes"
                type="number"
                value={this.state.legumes}
                onChange={(e) => this.setState({ legumes: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 120 }}
              />
              <TextField
                size="small"
                variant="outlined"
                id="standard-number"
                label="Calorie Free"
                type="number"
                value={this.state.calorieFree}
                onChange={(e) => this.setState({ calorieFree: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 120 }}
              />
            </Box>
          </DialogContent>
        );
      default:
        break;
    }
  }

  addGroup(name) {
    let temp = this.state.diet;
    let counter = 0;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id !== name) {
        counter += 1;
      }
    }
    if (counter === temp.length - 1) {
      console.log("ya existe");
      this.setState({ menuOpen: false });
    } else {
      console.log(group);
      let newGroup = JSON.parse(JSON.stringify(group));
      newGroup.id = name;
      console.log(group);
      temp.push(newGroup);
      this.setState({ menuOpen: false, diet: temp });
    }
  }

  addCollection(name) {
    let temp = this.state.diet;
    let newCollection = JSON.parse(JSON.stringify(collection));
    newCollection.id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    newCollection.title = name;
    temp[this.state.dietIndex].children.push(newCollection);
    this.setState({ diet: temp });
  }

  editCollection(title) {
    let temp = this.state.diet;
    temp[this.state.dietIndex].children[
      this.state.currentCollection
    ].title = title;
    this.setState({ diet: temp });
  }

  deleteCollection() {
    let temp = this.state.diet;
    temp[this.state.dietIndex].children.splice(
      [this.state.currentCollection],
      1
    );
    console.log(temp);
    this.setState({ diet: temp, currentCollection: null });
  }

  newDish(name) {
    let temp = this.state.diet;
    let newDish = JSON.parse(JSON.stringify(item));
    newDish.id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    newDish.name = name;
    newDish.details = this.state.details;
    newDish.portions.dairy.value = this.state.dairy;
    newDish.portions.legumes.value = this.state.legumes;
    newDish.portions.calorieFree.value = this.state.calorieFree;
    newDish.portions.oilsAndFats.value = this.state.oilsAndFats;
    newDish.portions.protein.value = this.state.protein;
    newDish.portions.cereals.value = this.state.cereals;
    newDish.portions.fruits.value = this.state.fruits;
    newDish.portions.vegetables.value = this.state.vegetables;
    console.log(newDish);
    let currentCollection =
      temp[this.state.dietIndex].children[this.state.currentCollection];

    currentCollection.children.push(newDish);
  }

  addDish(item) {
    let temp = this.state.diet;
    let newItem = JSON.parse(JSON.stringify(item));
    newItem.type = "item";
    newItem.id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    let currentCollection =
      temp[this.state.dietIndex].children[this.state.currentCollection] ||
      temp[this.state.dietIndex].children.slice(-1)[0];
    currentCollection.children.push(newItem);
    this.setState({ diet: temp });
    this.setState({
      dialogAlert: false,
      clone: false,
      openDialog: false,
      activeDialog: "newDish",
    });
  }

  editDish(name) {
    let temp = this.state.diet;
    let currentDish =
      temp[this.state.dietIndex].children[this.state.currentCollection]
        .children[this.state.currentItem];
    currentDish.name = name;
    currentDish.details = this.state.details;
    currentDish.portions.dairy.value = this.state.dairy;
    currentDish.portions.legumes.value = this.state.legumes;
    currentDish.portions.calorieFree.value = this.state.calorieFree;
    currentDish.portions.oilsAndFats.value = this.state.oilsAndFats;
    currentDish.portions.protein.value = this.state.protein;
    currentDish.portions.cereals.value = this.state.cereals;
    currentDish.portions.fruits.value = this.state.fruits;
    currentDish.portions.vegetables.value = this.state.vegetables;
    this.setState({ diet: temp });
  }

  deleteDish() {
    let temp = this.state.diet;
    temp[this.state.dietIndex].children[
      this.state.currentCollection
    ].children.splice([this.state.currentItem], 1);
    console.log(temp);
    this.setState({ diet: temp });
  }

  handleCloseSnack(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snack: false });
  }

  renderDietDialog() {
    return (
      this.state.dietHistory != null &&
      this.state.dietHistory.length > 0 && (
        <DialogContent dividers={true}>
          <Box>
            {this.state.dietHistory[this.state.viewDietIndex].diet.map(
              (item, i) => (
                <Box key={i}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="subtitle1">{item.id}</Typography>
                    <Typography>{item.time}</Typography>
                  </Box>
                  {item.children.map((item, i) => (
                    <Box key={i}>
                      <Typography
                        variant="subtitle1"
                        style={{ marginLeft: 10 }}
                      >
                        {item.title}
                      </Typography>
                      {item.children.map((item, i) => (
                        <Typography
                          key={i}
                          variant="subtitle1"
                          style={{ marginLeft: 20 }}
                        >
                          {item.name}
                        </Typography>
                      ))}
                    </Box>
                  ))}
                </Box>
              )
            )}
          </Box>
        </DialogContent>
      )
    );
  }

  resetSearchBox(index) {
    this.searchFilterMealTime(index);
    this.setState({ selectedMeal: index, keyword: "" });
    this.searchInput.focus();
  }

  speedDialDisabled(actionId) {
    console.log(actionId);
    switch (actionId) {
      case "dietRequest":
        if (!!this.state.user?.dietRequest) {
          return false;
        } else {
          return true;
        }
      default:
        return false;
    }
  }

  renderDialogSpeedDial() {
    switch (this.state.speedDialId) {
      case "privateNotes":
        return (
          <DialogContent>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              label="Notes"
              rows={20}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={
                this.state.user.notes !== undefined &&
                this.state.userNote === ""
                  ? this.state.user.notes
                  : this.state.userNote
              }
              onChange={(e) => this.setState({ userNote: e.target.value })}
            />
            <Divider style={{ height: 20, backgroundColor: "transparent" }} />
            <Button
              onClick={() => this.postNote()}
              color="primary"
              variant="contained"
              fullWidth
            >
              Save Note
            </Button>
          </DialogContent>
        );
      case "medicalh":
        return (
          <DialogContent>
            <MedicalHistory
              medicalHistory={this.state.user.medicalHistory}
              objectId={this.state.user.objectId}
            />
          </DialogContent>
        );
      case "newDietRequest":
        return (
          <DialogContent>
            <NewDiet newDiet={this.state.user.dietRequest} />
          </DialogContent>
        );
      case "userDetails":
        return (
          <DialogContent>
            <Box
              style={{
                ...mainStyles.boxRadius,
                ...{
                  flex: 1,
                  margin: "2px 10px 10px 10px",
                  padding: 10,
                  backgroundColor: `rgba( ${colors.background}, .1)`,
                },
              }}
            >
              {this.state.user === null ? (
                <CircularProgress />
              ) : (
                <UserDetails
                  objectId={this.props.match.params.id}
                  user={this.state.user}
                />
              )}
              <Divider style={{ height: 20, backgroundColor: "transparent" }} />
            </Box>
          </DialogContent>
        );
      case "previousDiets":
        return (
          <DialogContent>
            <Box>
              <Box
                style={{
                  ...mainStyles.boxRadius,
                  ...{
                    flex: 1,
                    margin: "2px 10px 10px 10px",
                    padding: 10,
                    backgroundColor: `rgba( ${colors.background}, .1)`,
                  },
                }}
              >
                {this.state.dietHistory === null ? (
                  <CircularProgress />
                ) : (
                  <Box style={{ maxHeight: 160, overflow: "auto" }}>
                    {this.state.dietHistory.length === 0 ? (
                      <Typography variant="subtitle1">
                        No tiene dietas
                      </Typography>
                    ) : (
                      <Box>
                        {this.state.dietHistory.map((item, i) => (
                          <Box
                            key={i}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography>
                              {item.title === undefined || item.title === ""
                                ? "Tu dieta NutriGeek"
                                : item.title}
                            </Typography>
                            <Box>
                              <Button
                                disableElevation
                                style={{ margin: 5 }}
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() =>
                                  this.setState({
                                    dietDialog: true,
                                    viewDietIndex: i,
                                    dialogTitleAlert: item.title,
                                  })
                                }
                              >
                                View
                              </Button>
                              <Button
                                disableElevation
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() =>
                                  this.setState({
                                    dietTemp: item.diet,
                                    objIdToEditTemp: item.objectId,
                                    dietTitleTemp: item.title,
                                    dialogAlertLoad: true,
                                  })
                                }
                              >
                                Load
                              </Button>
                            </Box>
                          </Box>
                        ))}
                        <Dialog open={this.state.dialogAlertLoad}>
                          <DialogTitle id="alert-dialog-title">
                            Alert
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Are you sure to load this diet?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() =>
                                this.setState({ dialogAlertLoad: false })
                              }
                              color="primary"
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() =>
                                this.setState({
                                  diet: this.state.dietTemp,
                                  objIdToEdit: this.state.objIdToEditTemp,
                                  dietTitle: this.state.dietTitleTemp,
                                  isEdit: true,
                                  dialogAlertLoad: false,
                                  dialogSpeedDial: false,
                                })
                              }
                              color="primary"
                              autoFocus
                            >
                              Aceptar
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              <Dialog
                open={this.state.dietDialog}
                fullWidth={true}
                maxWidth={"sm"}
              >
                <DialogTitle
                  disableTypography
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>{this.state.dialogTitleAlert}</Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => this.setState({ dietDialog: false })}
                  >
                    <Cancel />
                  </IconButton>
                </DialogTitle>
                {this.renderDietDialog()}
              </Dialog>
            </Box>
          </DialogContent>
        );
      default:
        break;
    }
  }

  renderDialogTitleSpeedDial() {
    switch (this.state.speedDialId) {
      case "privateNotes":
        return <Typography variant="h6">Private Notes</Typography>;
      case "medicalh":
        return <Typography variant="h6">Medical History</Typography>;
      case "newDietRequest":
        return <Typography variant="h6">New Diet Request</Typography>;
      case "userDetails":
        return <Typography variant="h6">User Details</Typography>;
      case "previousDiets":
        return <Typography variant="h6">Previous Diets</Typography>;
      default:
        break;
    }
  }

  postNote() {
    const params = this.props.match.params;
    axios
      .put(
        parseURL + "users/" + params.id,
        { notes: this.state.userNote },
        {
          headers: {
            "X-Parse-Application-Id": appId,
            "X-Parse-Master-Key": masterKey,
          },
        }
      )
      .then((res) => {
        console.log(res);
        this.setState({ dialogSpeedDial: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  speedDialDisabledIcons(id) {
    let x = null;
    switch (id) {
      case "newDietRequest":
        x = this.state.user.dietRequest === undefined ? true : false;
        return x;
      case "previousDiets":
        x = this.state.dietHistory === undefined ? true : false;
        return x;
      default:
        break;
    }
  }

  deleteDiet() {
    axios
      .delete(parseURL + "classes/Diet/" + this.state.dietObjectId, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ dialogAlertDeleteDiet: false });
        this.props.history.push("/diet");
      });
  }

  validateConversation() {
    const objectId = this.props.match.params.id;
    let constraints = JSON.stringify({
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: objectId,
      },
    });
    axios
      .get(`${parseURL}classes/VirtualDietitian/?where=${constraints}`, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        if (res.data.results.length !== 0) {
          this.setState({ disabledConversation: true });
        } else {
          this.setState({ disabledConversation: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  createNewConversation() {
    const objectId = this.props.match.params.id;
    let params = {
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: objectId,
      },
      teamMember: {
        __type: "Pointer",
        className: "_User",
        objectId: "Mv25rMIX1k",
      },
      messages: [
        {
          _id: 0,
          text:
            this.state.user.deviceLocale === "en"
              ? "NutriChat has started"
              : "NutriChat iniciado",
          createdAt: new Date(),
          system: true,
          user: {
            name: "NutriBot",
            _id: "NutriBot",
          },
          received: true,
        },
      ],
    };
    axios
      .post(`${parseURL}classes/VirtualDietitian/`, params, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({
          disabledConversation: true,
          snackForConversation: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  changePassword() {
    console.log(this.state.user);
    const params = {
      username:
        this.state.newUser === ""
          ? this.state.user.username
          : this.state.newUser,
      password: this.state.newPassword,
    };
    axios
      .put(`${parseURL}users/${this.state.user.objectId}`, params, {
        headers: {
          "X-Parse-Application-Id": appId,
          "X-Parse-Master-Key": masterKey,
        },
      })
      .then((res) => {
        console.log(res);
        this.setState({ dialogPassword: false, snackPassword: true }, () =>
          this.getUser(this.props.match.params.id)
        );
      })
      .catch((err) => {
        console.log(err.response.data);
        switch (err.response.data.code) {
          case 202:
            alert("este nombre de usuario ya esta en uso");
            break;
          case 203:
            alert("este correo ya esta en uso");
            break;
          default:
            break;
        }
      });
  }

  onChangeSortFood(value, index) {
    let numericValue = Number(value);
    this.holderDiet[index] = numericValue - 1;
    console.log(this.holderDiet);
  }

  sortFood() {
    let hasDuplicate = this.holderDiet.some(
      (val, i) => this.holderDiet.indexOf(val) !== i
    );
    if (hasDuplicate === true) {
      alert("position can not be repeat");
    } else {
      let newArray = new Array(this.holderDiet.length);
      this.state.diet.forEach((e, i) => {
        newArray.splice(this.holderDiet[i], 1, e);
      });
      this.setState({ diet: newArray, dialogSortFood: false });
    }
  }

  deleteMealTime() {
    let temp = this.state.diet;
    temp.splice(this.state.dietIndex, 1);
    this.setState({ diet: temp, dialogMealTime: false, dietIndex: 0 });
  }

  assignHolderDiet() {
    let tempHolder = [];
    this.state.diet.forEach((e, i) => {
      tempHolder.push(i);
    });
    this.holderDiet = tempHolder;
    console.log(this.holderDiet);
    this.setState({ dialogSortFood: true });
  }

  render() {
    return (
      <Box
        style={{
          paddingLeft: 90,
          color: "rgba(30, 30, 30, 1)",
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial example"
          style={{ position: "fixed", zIndex: 10, bottom: 20, right: 20 }}
          icon={<MoreHoriz />}
          onClick={() =>
            this.setState({ openSpeedDial: !this.state.openSpeedDial })
          }
          open={this.state.openSpeedDial}
        >
          {actions.map((action) => (
            <SpeedDialAction
              disabled={
                this.state.user !== null &&
                this.speedDialDisabledIcons(action._id)
              }
              key={action.name}
              icon={action.icon}
              tooltipTitle={
                this.state.dietHistory !== null &&
                (action._id === "previousDiets"
                  ? `${action.name} (${this.state.dietHistory.length})`
                  : action.name)
              }
              onClick={() =>
                this.setState({
                  dialogSpeedDial: true,
                  speedDialId: action._id,
                })
              }
            />
          ))}
        </SpeedDial>
        <Dialog
          open={this.state.dialogSpeedDial}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle
            disableTypography
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {this.renderDialogTitleSpeedDial()}
            <IconButton
              aria-label="close"
              onClick={() => this.setState({ dialogSpeedDial: false })}
            >
              <Cancel />
            </IconButton>
          </DialogTitle>
          {this.renderDialogSpeedDial()}
        </Dialog>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ backgroundColor: "blue", padding: 10 }}
        >
          <Button
            variant="contained"
            onClick={() => this.props.history.push("/diet")}
          >
            Back
          </Button>
          <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
          <Button
            variant="contained"
            onClick={() =>
              this.state.isEdit ? this.editDiet() : this.pushDiet()
            }
          >
            Save
          </Button>
        </Box>
        <Grid container>
          <Grid item style={{ flexDirection: "column" }} md={7}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: 12,
                textTransform: "uppercase",
                marginLeft: 10,
                marginTop: 10,
              }}
            >
              {this.state.user?.medicalHistory?.generalData.name ||
                this.state.user?.email ||
                null}
            </Typography>
            {this.state.diet == null ? (
              <Box>
                <Typography>Create new diet for this user</Typography>
                <Button
                  fullWidth
                  onClick={() =>
                    this.setState({
                      diet: JSON.parse(JSON.stringify(dietStructure_3)),
                      openDialog: true,
                      activeDialog: "dietTitle",
                    })
                  }
                >
                  3 opciones de menu
                </Button>
                <Button
                  fullWidth
                  onClick={() =>
                    this.setState({
                      diet: JSON.parse(JSON.stringify(dietStructure_7)),
                      openDialog: true,
                      activeDialog: "dietTitle",
                    })
                  }
                >
                  7 opciones de menu
                </Button>
              </Box>
            ) : (
              <Box
                style={{
                  ...mainStyles.boxRadius,
                  ...{
                    margin: "2px 10px 10px 10px",
                    padding: 10,
                    backgroundColor: `rgba( ${colors.background}, .1)`,
                  },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    size="small"
                    disableElevation
                    variant="contained"
                    onClick={() =>
                      this.setState({
                        openDialog: true,
                        activeDialog: "dietTitle",
                      })
                    }
                  >
                    {this.state.dietTitle || "Add title"}
                  </Button>
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    <Button
                      onClick={(event) =>
                        this.setState({
                          menuOpen: true,
                          anchorEl: event.currentTarget,
                        })
                      }
                    >
                      Add food time
                    </Button>
                    <Button
                      onClick={() =>
                        this.setState({
                          openDialog: true,
                          activeDialog: "newCollection",
                        })
                      }
                    >
                      New Category
                    </Button>
                    <Button
                      disabled={this.state.currentCollection == null}
                      onClick={() =>
                        this.setState({
                          openDialog: true,
                          activeDialog: "newDish",
                          details: null,
                          dairy: 0,
                          legumes: 0,
                          calorieFree: 0,
                          oilsAndFats: 0,
                          protein: 0,
                          cereals: 0,
                          fruits: 0,
                          vegetables: 0,
                        })
                      }
                    >
                      <Ikon
                        icon={"Dinner"}
                        size={18}
                        color={"FFFFFF"}
                        style={{ marginRight: 5 }}
                      />
                      Add dish
                    </Button>
                  </ButtonGroup>
                </Box>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  getContentAnchorEl={null}
                  open={this.state.menuOpen}
                  onClose={() => this.setState({ menuOpen: false })}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                  {menuItems.map((item, i) => (
                    <MenuItem key={i} onClick={() => this.addGroup(item.item)}>
                      {renderMealTime(item.item)[1]}
                    </MenuItem>
                  ))}
                </Menu>
                <Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    {this.state.diet.map((item, i) => (
                      <Box
                        key={i}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          width: 60,
                          height: 60,
                          backgroundColor:
                            this.state.dietIndex === i
                              ? colors.primary
                              : colors.muted,
                          color:
                            this.state.dietIndex === i
                              ? `#FFFFFF`
                              : colors.primaryLight,
                          borderRadius: 5,
                          margin: 5,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          this.setState({
                            dietIndex: i,
                            currentCollection: null,
                            activeDialog: "title",
                          })
                        }
                      >
                        <Ikon
                          icon={renderMealTime(item.id)[0]}
                          size={30}
                          style={{ paddingBottom: 5 }}
                          color={"FFFFFF"}
                        />
                        <Typography style={{ fontSize: 8 }}>
                          {renderMealTime(item.id)[1]}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Nestable
                    maxDepth={2}
                    threshold={30}
                    items={this.state.diet[this.state.dietIndex].children}
                    renderItem={this.renderItem}
                    onChange={(items) => this.validateMovement(items)}
                  />
                </Box>
                <Divider
                  style={{ height: 20, backgroundColor: "transparent" }}
                />
                <Button
                  disabled={this.state.diet.length === 1 ? true : false}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => this.setState({ dialogMealTime: true })}
                >
                  Delete Food Time
                </Button>
              </Box>
            )}
            <Box
              style={{
                ...mainStyles.boxRadius,
                ...{
                  margin: "2px 10px 10px 10px",
                  padding: 10,
                  backgroundColor: `rgba( ${colors.background}, .1)`,
                },
              }}
            >
              <ButtonGroup
                disableElevation
                variant="contained"
                color="primary"
                size="small"
              >
                <Button
                  onClick={() => this.setState({ dialogAlertDeleteDiet: true })}
                >
                  Delete
                </Button>
                <Button
                  disabled={this.state.disabledConversation}
                  onClick={() => this.createNewConversation()}
                >
                  Start Conversation
                </Button>
                <Button onClick={() => this.setState({ dialogPassword: true })}>
                  Assign new password
                </Button>
                <Button
                  disabled={this.state.diet === null}
                  onClick={() => this.assignHolderDiet()}
                >
                  Sort Food Time
                </Button>
              </ButtonGroup>
              <Dialog open={this.state.dialogAlertDeleteDiet}>
                <DialogTitle>Alert</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure to delete this diet?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() =>
                      this.setState({ dialogAlertDeleteDiet: false })
                    }
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={() => this.deleteDiet()} color="primary">
                    Aceptar
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Grid>
          <Grid item md={5}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: 12,
                textTransform: "uppercase",
                marginLeft: 10,
                marginTop: 10,
              }}
            >
              Dishes
            </Typography>
            <Box
              style={{
                ...mainStyles.boxRadius,
                ...{
                  flex: 1,
                  margin: "2px 10px 10px 10px",
                  padding: 10,
                  backgroundColor: `rgba( ${colors.background}, .1)`,
                },
              }}
            >
              {this.state.user != null && (
                <Box>
                  <Box>
                    <ButtonGroup
                      fullWidth
                      disableElevation
                      variant="contained"
                      size="small"
                      color="primary"
                    >
                      <Button
                        color={
                          this.state.selectedMeal === null
                            ? "secondary"
                            : "primary"
                        }
                        onClick={() =>
                          this.setState(
                            {
                              dishes: this.array,
                              selectedMeal: null,
                            },
                            () => (this.arrayholder = this.array)
                          )
                        }
                      >
                        ALL
                      </Button>
                      <Button
                        color={
                          this.state.selectedMeal === 0
                            ? "secondary"
                            : "primary"
                        }
                        onClick={() => this.resetSearchBox(0)}
                      >
                        BKFTS
                      </Button>
                      <Button
                        color={
                          this.state.selectedMeal === 1
                            ? "secondary"
                            : "primary"
                        }
                        onClick={() => this.resetSearchBox(1)}
                      >
                        Lunch
                      </Button>
                      <Button
                        color={
                          this.state.selectedMeal === 2
                            ? "secondary"
                            : "primary"
                        }
                        onClick={() => this.resetSearchBox(2)}
                      >
                        Dinner
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup
                      style={{ marginTop: 10 }}
                      fullWidth
                      disableElevation
                      variant="contained"
                      size="small"
                      color="primary"
                    >
                      <Button
                        color={
                          this.state.selectedMeal === 3
                            ? "secondary"
                            : "primary"
                        }
                        onClick={() => this.resetSearchBox(3)}
                      >
                        Snack
                      </Button>
                      <Button
                        color={
                          this.state.selectedMeal === 4
                            ? "secondary"
                            : "primary"
                        }
                        onClick={() => this.resetSearchBox(4)}
                      >
                        Add-On
                      </Button>
                      <Button
                        color={
                          this.state.selectedMeal === 5
                            ? "secondary"
                            : "primary"
                        }
                        onClick={() => this.resetSearchBox(5)}
                      >
                        Salads
                      </Button>
                      <Button
                        color={
                          this.state.selectedMeal === 6
                            ? "secondary"
                            : "primary"
                        }
                        onClick={() => this.resetSearchBox(6)}
                      >
                        Garrison
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Box>
              )}
              <FormGroup style={{ marginTop: 10, marginBottom: 10 }}>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Keyword"
                  inputRef={(input) => {
                    this.searchInput = input;
                  }}
                  value={this.state.keyword}
                  onChange={(text) => this.searchFilter(text.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormGroup>
              {this.state.dishes !== null && (
                <Box>
                  {this.state.dishes
                    .slice(0, this.state.dishLimit)
                    .map((item, i) => (
                      <Box
                        key={i}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                          backgroundColor: "white",
                          marginBottom: 10,
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingLeft: 5,
                          paddingRight: 5,
                          borderRadius: 5,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          this.state.diet[this.state.dietIndex].children
                            .length > 0 && this.addDish(item)
                        }
                      >
                        <Box>
                          <Typography
                            style={{ fontSize: 14, fontWeight: "bold" }}
                          >
                            {item.isVeggieOption && (
                              <Eco
                                style={{
                                  height: 18,
                                  marginBottom: -4,
                                  marginLeft: -5,
                                }}
                              />
                            )}
                            {item.name}
                          </Typography>
                          <Typography style={{ fontSize: 12 }}>
                            {item.details}
                          </Typography>
                          {!!item.portions && (
                            <AvatarGroup max={8} spacing={0}>
                              {Object.entries(dishPortions).map(
                                ([key, value]) =>
                                  item.portions[key].value > 0 && (
                                    <Avatar
                                      key={key}
                                      style={{
                                        height: 15,
                                        width: 15,
                                        backgroundColor: value.color,
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontSize: 10,
                                          fontWeight: "bold",
                                          color: "white",
                                          paddingLeft: 1,
                                        }}
                                      >
                                        {item.portions[key].value}
                                      </Typography>
                                    </Avatar>
                                  )
                              )}
                              {/* <Avatar
                              style={{
                                height: 15,
                                width: 15,
                                backgroundColor: "#ffc41e",
                              }}
                            >
                              <Typography
                                style={{ fontSize: 10, color: "#000000" }}
                              >
                                {item.portions?.cereals.value}
                              </Typography>
                            </Avatar>
                            <Avatar
                              style={{
                                height: 15,
                                width: 15,
                                backgroundColor: "red",
                              }}
                            >
                              <Typography style={{ fontSize: 10 }}>
                                {item.portions?.dairy.value}
                              </Typography>
                            </Avatar>
                            <Avatar
                              style={{
                                height: 15,
                                width: 15,
                                backgroundColor: "red",
                              }}
                            >
                              <Typography style={{ fontSize: 10 }}>
                                {item.portions?.dairy.value}
                              </Typography>
                            </Avatar> */}
                            </AvatarGroup>
                          )}
                        </Box>
                        <Typography style={{ fontSize: 12 }}>
                          {item.calories} cal
                        </Typography>
                      </Box>
                    ))}
                  {this.state.dishLimit <= this.state.dishes.length && (
                    <Button fullWidth onClick={() => this.showMore()}>
                      Show More
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Dialog open={this.state.openDialog} fullWidth={true} maxWidth={"sm"}>
          <DialogTitle
            disableTypography
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>{this.renderDialogTitle()}</Typography>
            <IconButton
              aria-label="close"
              onClick={() =>
                this.setState({ openDialog: false, activeDialog: "dietTitle" })
              }
            >
              <Cancel />
            </IconButton>
          </DialogTitle>
          {this.renderDialog()}
          <DialogActions
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              justifyContent:
                this.state.activeDialog !== "dietTitle"
                  ? "space-between"
                  : "flex-end",
            }}
          >
            {this.state.activeDialog === "newCollection" ||
            this.state.activeDialog === "editCollection" ? (
              <Button
                style={{
                  color:
                    this.state.activeDialog === "newCollection"
                      ? colors.muted
                      : "red",
                }}
                disabled={this.state.activeDialog === "newCollection"}
                onClick={() => this.setState({ dialogAlert: true })}
              >
                Delete
              </Button>
            ) : (
              <Box>
                {this.state.activeDialog !== "dietTitle" && (
                  <Button
                    onClick={(event) =>
                      this.setState({
                        menuOpen2: true,
                        anchorEl2: event.currentTarget,
                      })
                    }
                  >
                    <MoreHoriz />
                  </Button>
                )}
              </Box>
            )}
            <Button onClick={() => this.dialogAction()} color="primary">
              Accept
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl2}
          getContentAnchorEl={null}
          open={this.state.menuOpen2}
          onClose={() => this.setState({ menuOpen2: false })}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MenuItem
            disabled={
              this.state.activeDialog === "newCollection" ||
              this.state.activeDialog === "editCollection"
            }
            onClick={() => this.postDish()}
          >
            Add to Dishes
          </MenuItem>
          <MenuItem
            disabled={
              this.state.activeDialog === "newDish" ||
              this.state.activeDialog === "newCollection" ||
              this.state.activeDialog === "editCollection"
            }
            onClick={() =>
              this.setState({
                menuOpen2: false,
                clone: true,
                dialogAlert: true,
              })
            }
          >
            Clone
          </MenuItem>
          <MenuItem
            disabled={
              this.state.activeDialog === "newDish" ||
              this.state.activeDialog === "newCollection"
            }
            style={{ color: "red" }}
            onClick={() =>
              this.setState({ dialogAlert: true, menuOpen2: false })
            }
          >
            Delete
          </MenuItem>
        </Menu>
        <Dialog open={this.state.dialogAlert}>
          <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.clone === true
                ? "Are you sure tu clone"
                : "Are you sure to delete it"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogAlert: false })}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                this.state.clone === true
                  ? this.addDish(
                      this.state.diet[this.state.dietIndex].children[
                        this.state.currentCollection
                      ].children[this.state.currentItem]
                    )
                  : this.dialogActionDelete()
              }
              color="primary"
              autoFocus
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          key={"top" + "right"}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={4000}
          open={this.state.snack}
          onClose={(e, r) => this.handleCloseSnack(e, r)}
        >
          <Alert
            onClose={(e, r) => this.handleCloseSnack(e, r)}
            severity="success"
          >
            This is a success message!
          </Alert>
        </Snackbar>
        <Snackbar
          key={"top" + "right"}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={4000}
          open={this.state.snackForConversation}
          onClose={() => this.setState({ snackForConversation: false })}
        >
          <Alert
            onClose={() => this.setState({ snackForConversation: false })}
            severity="success"
            variant="filled"
          >
            Chat created, continue on app
          </Alert>
        </Snackbar>
        <Dialog open={this.state.dialogPassword}>
          <DialogTitle id="alert-dialog-title">
            Create new Username and Password
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              defaultValue={
                this.state.user !== null && this.state.user.username
              }
              onChange={(e) => this.setState({ newUser: e.target.value })}
            />
            <TextField
              fullWidth
              style={{ marginTop: 25 }}
              label="Password"
              variant="outlined"
              onChange={(e) => this.setState({ newPassword: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogPassword: false })}
              color="secondary"
            >
              Cancel
            </Button>
            <Button color="primary" onClick={() => this.changePassword()}>
              Accept
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          key={"top" + "right"}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={4000}
          open={this.state.snackPassword}
          onClose={() => this.setState({ snackPassword: false })}
        >
          <Alert
            onClose={() => this.setState({ snackPassword: false })}
            severity="success"
            variant="filled"
          >
            User updated successfully
          </Alert>
        </Snackbar>
        <Dialog open={this.state.dialogSortFood}>
          <DialogTitle>Sort Food Time</DialogTitle>
          <DialogContent>
            {this.state.diet !== null && (
              <Box>
                {this.state.diet.map((item, i) => (
                  <Box
                    key={i}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Ikon
                        icon={renderMealTime(item.id)[0]}
                        size={30}
                        style={{ paddingBottom: 5 }}
                        color={"FFFFFF"}
                      />
                      <Typography style={{ fontSize: 8 }}>
                        {renderMealTime(item.id)[1]}
                      </Typography>
                    </Box>
                    <Box style={{ marginLeft: 20 }}>
                      <TextField
                        fullWidth
                        type="number"
                        inputProps={{
                          min: "1",
                          step: "1",
                          max: this.state.diet.length,
                          style: { textAlign: "center", fontWeight: "bold" },
                        }}
                        defaultValue={i + 1}
                        onChange={(e) =>
                          this.onChangeSortFood(e.target.value, i)
                        }
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogSortFood: false })}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={() => this.sortFood()} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.dialogMealTime}>
          <DialogTitle>Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to delete this meal time?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogMealTime: false })}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={() => this.deleteMealTime()} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default Results;

let menuItems = [
  {
    item: "breakfast",
  },
  {
    item: "earlySnack",
  },
  {
    item: "lunch",
  },
  {
    item: "lateSnack",
  },
  {
    item: "dinner",
  },
  {
    item: "workoutSnack",
  },
];

const actions = [
  { _id: "privateNotes", icon: <Lock />, name: "Private Notes" },
  { _id: "medicalh", icon: <LocalHospital />, name: "Medical History" },
  { _id: "newDietRequest", icon: <NewReleases />, name: "New Diet Request" },
  { _id: "previousDiets", icon: <Assignment />, name: `Previous Diets` },
  { _id: "userDetails", icon: <Person />, name: "User Details" },
];

const dishPortions = {
  cereals: {
    name: "Cereals",
    _id: "FTXvmJR0iY",
    color: "#ffc41e",
    value: 0,
  },
  protein: {
    name: "Protein",
    _id: "naq38iCrpg",
    color: "#fb5753",
    value: 0,
  },
  vegetables: {
    name: "Vegetables",
    _id: "X3EU29CGbd",
    color: "#29ab60",
    value: 0,
  },
  fruits: {
    name: "Fruits",
    _id: "rvDflv32V7",
    color: "#fd3d16",
    value: 0,
  },
  dairy: {
    name: "Dairy",
    _id: "2GhQ5T4MfT",
    color: "#1d3a76",
    value: 0,
  },
  legumes: {
    name: "Legumes",
    _id: "a9XtMSpa7M",
    color: "#f0537f",
    value: 0,
  },
  oilsAndFats: {
    name: "Oils and Fats",
    _id: "8J0JiF6C9j",
    color: "#737877",
    value: 0,
  },
  calorieFree: {
    name: "Calorie Free",
    _id: "GHAUm8i0KC",
    color: "#553166",
    value: 0,
  },
};
