export const login = (value) => {
  localStorage.setItem("sessionToken", value);
};

export const logout = () => {
  localStorage.removeItem("sessionToken");
};

export const isLogin = () => {
  if (localStorage.getItem("sessionToken")) {
    return true;
  }

  return false;
};
