import React, { Component } from "react";
import {
  parseURL,
  appId,
  rowsPerPage,
  imgixURL,
  masterKey,
} from "../resources/variables";
import axios from "axios";
import { colors } from "../styles/styles";
import { Alert } from "@material-ui/lab";
import {
  AddCircle,
  Cancel,
  CheckCircle,
  PlayCircleFilled,
  Search,
  RemoveCircle,
  PublishOutlined,
  DeleteOutlineOutlined,
  ImageSearch,
} from "@material-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
} from "@material-ui/core/";

import { renderMealTimeDishes } from "../resources/functions";

let inputClick1 = React.createRef();
let inputClick2 = React.createRef();
let inputClick3 = React.createRef();

export class Meals extends Component {
  state = {
    isThinking: true,
    dishes: null,
    equivalentCategory: null,
    dishPortions: JSON.parse(JSON.stringify(dishPortions)),
    mealTime: JSON.parse(JSON.stringify(mealTimeConst)),
    openDialog: false,
    recipeDialog: false,
    //
    newDishName: "",
    newIsPortion: false,
    newHasRecipe: false,
    newVeggieOption: false,
    newDishDetails: null,
    newArrayIngredients: [],
    newArraySteps: [],
    //
    editDishName: "",
    editDishDetails: null,
    editIsPortion: false,
    editHasRecipe: false,
    editVeggieOption: false,
    editArrayIngredients: [],
    editArraySteps: [],
    //
    currentDialog: "newDish",
    dishIndex: 0,
    page: 0,
    rowsPerPage: 5,
    keyword: "",
    dialogAlert: false,
    deleteType: "dish",
    language: "en",
    //
    loadingImage1: false,
    loadingImage2: false,
    loadingImage3: false,
    disabledImage1: false,
    disabledImage2: false,
    disabledImage3: false,
    imageOne: { url: "", file: undefined },
    imageTwo: { url: "", file: undefined },
    imageThree: { url: "", file: undefined },
    urlOne: undefined,
    urlTwo: undefined,
    urlThree: undefined,
    snackImage: false,
    alertType: true,
  };

  componentDidMount() {
    this.imagesArray = [undefined, undefined, undefined];
    this.arrayholder = [];
    this.getDishes();
    this.getEquivalentCategory();
  }

  getDishes() {
    this.setState({ isThinking: true });
    axios
      .get(parseURL + "classes/Dish?order=-createdAt&limit=1000", {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        this.setState({ isThinking: false, dishes: res.data.results });
        this.arrayholder = res.data.results;
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isThinking: false });
      });
  }

  getEquivalentCategory() {
    this.setState({ isThinking: true });
    axios
      .get(parseURL + "classes/EquivalentCategory?&order=updatedAt", {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        this.setState({
          isThinking: false,
          equivalentCategory: res.data.results,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isThinking: false });
      });
  }

  createObject(itemKey, number) {
    let numberValue = Number(number);
    let temp = this.state.dishPortions;
    for (const [key, value] of Object.entries(temp)) {
      if (itemKey === key) {
        value.value = numberValue;
      }
    }
    this.setState({ dishPortions: temp });
  }

  editObject(itemKey, number) {
    let numberValue = Number(number);
    let temp = this.state.dishes;
    temp[this.state.dishIndex].portions[itemKey].value = numberValue;
    this.setState({ dishes: temp });
  }

  postNewDish() {
    let caloriesAdded = 0;
    let multi = 0;
    let temp = this.state.dishPortions;
    for (const [key, value] of Object.entries(temp)) {
      multi = 0;
      for (let i = 0; i < this.state.equivalentCategory.length; i++) {
        if (key === this.state.equivalentCategory[i].itemId) {
          multi = this.state.equivalentCategory[i].calories * value.value;
        }
      }
      caloriesAdded += multi;
    }
    let params = {
      name: this.state.newDishName,
      details:
        this.state.newDishDetails === "" ? null : this.state.newDishDetails,
      mealTime: this.state.mealTime,
      isPortion: this.state.newIsPortion,
      language: this.state.language,
      isVeggieOption: this.state.newVeggieOption,
      hasRecipe: this.state.newHasRecipe,
      portions: this.state.dishPortions,
      calories: caloriesAdded,
      ingredients: this.state.newArrayIngredients,
      recipeSteps: this.state.newArraySteps,
    };
    axios
      .post(parseURL + "classes/Dish", params, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((response) => {
        console.log(response);
        this.setState({ openDialog: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ openDialog: false });
      })
      .finally(() => {
        this.setState({
          isThinking: false,
          newArrayIngredients: [],
          newHasRecipe: false,
          newArraySteps: [],
          dishPortions: JSON.parse(JSON.stringify(dishPortions)),
          mealTime: JSON.parse(JSON.stringify(mealTimeConst)),
          newIsPortion: false,
          newVeggieOption: false,
          newDishDetails: "",
          newDishName: "",
          currentDialog: "newDish",
          language: "en",
        });
        this.getDishes();
      });
  }

  putEditDish() {
    let tempImages = [];
    this.imagesArray.forEach((e) => {
      if (e !== undefined && e !== null) {
        tempImages.push(e);
      }
    });
    let temp = this.state.dishes[this.state.dishIndex].portions;
    let caloriesAdded = 0;
    let multi = 0;
    for (const [key, value] of Object.entries(temp)) {
      multi = 0;
      for (let i = 0; i < this.state.equivalentCategory.length; i++) {
        if (key === this.state.equivalentCategory[i].itemId) {
          multi = this.state.equivalentCategory[i].calories * value.value;
        }
      }
      caloriesAdded += multi;
    }
    let params = {
      name: this.state.editDishName,
      details:
        this.state.editDishDetails === "" ? null : this.state.editDishDetails,
      isPortion: this.state.editIsPortion,
      isVeggieOption: this.state.editVeggieOption,
      hasRecipe: this.state.editHasRecipe,
      language: this.state.language,
      portions: this.state.dishes[this.state.dishIndex].portions,
      mealTime: this.state.mealTime,
      calories: caloriesAdded,
      ingredients: this.state.editArrayIngredients,
      recipeSteps: this.state.editArraySteps,
      images: tempImages,
    };
    axios
      .put(
        parseURL +
          "classes/Dish/" +
          this.state.dishes[this.state.dishIndex].objectId,
        params,
        {
          headers: {
            "X-Parse-Application-Id": appId,
          },
        }
      )
      .then((response) => {
        console.log(response);
        this.setState({ openDialog: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ openDialog: false });
      })
      .finally(() => {
        this.setState({
          isThinking: false,
          mealTime: JSON.parse(JSON.stringify(mealTimeConst)),
          currentDialog: "newDish",
          language: "en",
        });
        this.getDishes();
      });
  }

  nuevafun() {
    if (this.state.urlOne === undefined) {
      this.setState({ disabledImage1: false });
      this.imagesArray[0] = undefined;
    } else {
      this.setState({ disabledImage1: true });
      this.imagesArray[0] = this.state.urlOne;
    }
    if (this.state.urlTwo === undefined) {
      this.setState({ disabledImage2: false });
      this.imagesArray[1] = undefined;
    } else {
      this.setState({ disabledImage2: true });
      this.imagesArray[1] = this.state.urlTwo;
    }
    if (this.state.urlThree === undefined) {
      this.setState({ disabledImage3: false });
      this.imagesArray[2] = undefined;
    } else {
      this.setState({ disabledImage3: true });
      this.imagesArray[2] = this.state.urlThree;
    }
  }

  uploadImage(name, data, index) {
    switch (index) {
      case 0:
        this.setState({ loadingImage1: true });
        break;
      case 1:
        this.setState({ loadingImage2: true });
        break;
      case 2:
        this.setState({ loadingImage3: true });
        break;
      default:
        break;
    }
    axios
      .post(`${parseURL}files/dish.jpeg`, data, {
        headers: {
          "X-Parse-Application-Id": appId,
          "Content-Type": "image/jpeg",
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log(res.data.name);
        this.imagesArray[index] = res.data.name;
        this.setState({ snackImage: true });
        this.setState({ alertType: true });
        switch (index) {
          case 0:
            this.setState({
              loadingImage1: false,
              disabledImage1: true,
              urlOne: res.data.name,
            });
            break;
          case 1:
            this.setState({
              loadingImage2: false,
              disabledImage2: true,
              urlTwo: res.data.name,
            });
            break;
          case 2:
            this.setState({
              loadingImage3: false,
              disabledImage3: true,
              urlThree: res.data.name,
            });
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        this.setState({ snackImage: true });
        this.setState({ alertType: false });
        console.log(err);
      })
      .finally(() => console.log(this.imagesArray));
  }

  deletedImage(name, index) {
    console.log(name);
    axios
      .delete(`${parseURL}files/${name}`, {
        headers: {
          "X-Parse-Application-Id": appId,
          "X-Parse-Master-Key": masterKey,
        },
      })
      .then((res) => {
        this.imagesArray[index] = undefined;
        console.log(res);
        console.log(this.imagesArray);
        switch (index) {
          case 0:
            this.setState({
              urlOne: undefined,
            });
            break;
          case 1:
            this.setState({
              urlTwo: undefined,
            });
            break;
          case 2:
            this.setState({
              urlThree: undefined,
            });
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderDialog() {
    let currentDialog = this.state.currentDialog;
    return (
      <DialogContent>
        <Box display="flex" justifyContent="space-between">
          <TextField
            style={{ width: "72%" }}
            size="small"
            variant="outlined"
            label="Dish name"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={
              currentDialog === "newDish"
                ? null
                : this.state.dishes[this.state.dishIndex].name
            }
            onChange={(e) =>
              currentDialog === "newDish"
                ? this.setState({ newDishName: e.target.value })
                : this.setState({ editDishName: e.target.value })
            }
          />
          <TextField
            style={{ width: "25%" }}
            select
            variant="outlined"
            size="small"
            label="Lenguage"
            defaultValue={
              currentDialog === "newDish"
                ? "en"
                : this.state.dishes[this.state.dishIndex].language === undefined
                ? "en"
                : this.state.dishes[this.state.dishIndex].language
            }
            onChange={(e) => this.setState({ language: e.target.value })}
          >
            {["en", "es"].map((e, i) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Divider style={{ height: 20, backgroundColor: "transparent" }} />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          label="Details"
          multiline
          rows={4}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={
            currentDialog === "newDish"
              ? null
              : this.state.dishes[this.state.dishIndex].details
          }
          onChange={(e) =>
            currentDialog === "newDish"
              ? this.setState({ newDishDetails: e.target.value })
              : this.setState({ editDishDetails: e.target.value })
          }
        />
        <Divider style={{ height: 20, backgroundColor: "transparent" }} />
        <DialogContentText style={{ fontSize: 12, fontWeight: "500" }}>
          Equivalents
        </DialogContentText>
        {this.state.equivalentCategory != null && (
          <Grid container>
            {Object.entries(this.state.dishPortions).map(([key, value]) => (
              <Grid item xs={3} key={key}>
                <TextField
                  id="standard-number"
                  variant="outlined"
                  size="small"
                  type="number"
                  style={{ width: 120, marginBottom: 15 }}
                  label={
                    <Typography
                      style={{
                        fontSize: 16,
                        lineHeight: 1,
                        color: value.color,
                      }}
                    >
                      {value.name}
                    </Typography>
                  }
                  defaultValue={
                    currentDialog === "newDish"
                      ? null
                      : this.state.dishes[this.state.dishIndex].portions[key]
                          .value
                  }
                  onChange={(e) =>
                    currentDialog === "newDish"
                      ? this.createObject(key, e.target.value)
                      : this.editObject(key, e.target.value)
                  }
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Divider style={{ height: 20, backgroundColor: "transparent" }} />
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={
                  currentDialog === "newDish"
                    ? this.state.newIsPortion
                    : this.state.editIsPortion
                }
                onChange={(e) =>
                  currentDialog === "newDish"
                    ? this.setState({ newIsPortion: e.target.checked })
                    : this.setState({ editIsPortion: e.target.checked })
                }
                name="gilad"
                size="small"
              />
            }
            label={
              <Typography
                style={{
                  fontSize: 13,
                  fontWeight: "500",
                  textTransform: "uppercase",
                }}
              >
                Show Portions
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Switch
                checked={
                  currentDialog === "newDish"
                    ? this.state.newVeggieOption
                    : this.state.editVeggieOption
                }
                onChange={(e) =>
                  currentDialog === "newDish"
                    ? this.setState({ newVeggieOption: e.target.checked })
                    : this.setState({ editVeggieOption: e.target.checked })
                }
                name="gilad"
                size="small"
              />
            }
            label={
              <Typography
                style={{
                  fontSize: 13,
                  fontWeight: "500",
                  textTransform: "uppercase",
                }}
              >
                Veggie Option
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Switch
                checked={
                  currentDialog === "newDish"
                    ? this.state.newHasRecipe
                    : this.state.editHasRecipe
                }
                onChange={(e) =>
                  currentDialog === "newDish"
                    ? this.setState({ newHasRecipe: e.target.checked })
                    : this.setState({ editHasRecipe: e.target.checked })
                }
                name="gilad"
                size="small"
              />
            }
            label={
              <Typography
                style={{
                  fontSize: 13,
                  fontWeight: "500",
                  textTransform: "uppercase",
                }}
              >
                Has recipe
              </Typography>
            }
          />
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => this.setState({ recipeDialog: true })}
          >
            Receta
          </Button>
        </FormGroup>
        <Grid container>
          {mealTimeConst.map((item, i) => (
            <Grid item xs={4} key={i}>
              <FormControlLabel
                key={i}
                label={renderMealTimeDishes(item.id)}
                checked={this.state.mealTime[i]?.checked}
                control={<Checkbox />}
                onChange={() => this.onPressMealTime(item, i)}
              />
            </Grid>
          ))}
        </Grid>
        <Box
          style={{ marginTop: 15 }}
          display="flex"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <input
              ref={inputClick1}
              type="file"
              hidden="hidden"
              onChange={(e) => {
                let newState = {
                  url: URL.createObjectURL(e.target.files[0]),
                  file: e.target.files[0],
                };
                this.setState({ imageOne: newState, urlOne: undefined }, () => {
                  this.uploadImage(
                    this.state.imageOne.file.name,
                    this.state.imageOne.file,
                    0
                  );
                });
              }}
            />
            {this.state.urlOne === undefined ? (
              <ImageSearch style={{ width: 150, height: 100 }} />
            ) : (
              <img
                width={150}
                height={100}
                src={`${imgixURL}${this.state.urlOne}?w=150&h100&q=30`}
              />
            )}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              {this.state.urlOne === undefined && (
                <div>
                  {this.state.disabledImage1 === false && (
                    <Button
                      startIcon={<PublishOutlined />}
                      disabled={this.state.loadingImage1}
                      style={{
                        backgroundColor: "#009688",
                        color: "#fff",
                        fontSize: 10,
                      }}
                      onClick={() => {
                        inputClick1.current.click();
                      }}
                    >
                      {this.state.loadingImage1 === true ? (
                        <CircularProgress color="secondary" size={20} />
                      ) : (
                        "Choose File"
                      )}
                    </Button>
                  )}
                </div>
              )}
              <div>
                {this.state.disabledImage1 === true && (
                  <DeleteOutlineOutlined
                    cursor="pointer"
                    onClick={() => {
                      this.setState(
                        {
                          imageOne: { url: "", file: undefined },
                          disabledImage1: false,
                          loadingImage1: false,
                        },
                        () => {
                          this.deletedImage(this.state.urlOne, 0);
                        }
                      );
                    }}
                  />
                )}
              </div>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <input
              ref={inputClick2}
              type="file"
              hidden="hidden"
              onChange={(e) => {
                let newState = {
                  url: URL.createObjectURL(e.target.files[0]),
                  file: e.target.files[0],
                };
                this.setState({ imageTwo: newState, urlTwo: undefined }, () => {
                  this.uploadImage(
                    this.state.imageTwo.file.name,
                    this.state.imageTwo.file,
                    1
                  );
                });
              }}
            />
            {this.state.urlTwo === undefined ? (
              <ImageSearch style={{ width: 150, height: 100 }} />
            ) : (
              <img
                width={150}
                height={100}
                src={`${imgixURL}${this.state.urlTwo}?w=150&h100&q=30`}
              />
            )}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              {this.state.urlTwo === undefined && (
                <div>
                  {this.state.disabledImage2 === false && (
                    <Button
                      startIcon={<PublishOutlined />}
                      disabled={this.state.loadingImage2}
                      style={{
                        backgroundColor: "#009688",
                        color: "#fff",
                        fontSize: 10,
                      }}
                      onClick={() => {
                        inputClick2.current.click();
                      }}
                    >
                      {this.state.loadingImage2 === true ? (
                        <CircularProgress color="secondary" size={20} />
                      ) : (
                        "Choose File"
                      )}
                    </Button>
                  )}
                </div>
              )}
              {this.state.disabledImage2 === true && (
                <DeleteOutlineOutlined
                  cursor="pointer"
                  onClick={() => {
                    this.setState(
                      {
                        imageTwo: { url: "", file: undefined },
                        disabledImage2: false,
                        loadingImage2: false,
                      },
                      () => {
                        this.deletedImage(this.state.urlTwo, 1);
                      }
                    );
                  }}
                />
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <input
              ref={inputClick3}
              type="file"
              hidden="hidden"
              onChange={(e) => {
                let newState = {
                  url: URL.createObjectURL(e.target.files[0]),
                  file: e.target.files[0],
                };
                this.setState(
                  { imageThree: newState, urlThree: undefined },
                  () => {
                    this.uploadImage(
                      this.state.imageThree.file.name,
                      this.state.imageThree.file,
                      2
                    );
                  }
                );
              }}
            />
            {this.state.urlThree === undefined ? (
              <ImageSearch style={{ width: 150, height: 100 }} />
            ) : (
              <img
                width={150}
                height={100}
                src={`${imgixURL}${this.state.urlThree}?w=150&h100&q=30`}
              />
            )}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              {this.state.urlThree === undefined && (
                <div>
                  {this.state.disabledImage3 === false && (
                    <Button
                      startIcon={<PublishOutlined />}
                      disabled={this.state.loadingImage3}
                      style={{
                        backgroundColor: "#009688",
                        color: "#fff",
                        fontSize: 10,
                      }}
                      onClick={() => {
                        inputClick3.current.click();
                      }}
                    >
                      {this.state.loadingImage3 === true ? (
                        <CircularProgress color="secondary" size={20} />
                      ) : (
                        "Choose File"
                      )}
                    </Button>
                  )}
                </div>
              )}
              {this.state.disabledImage3 === true && (
                <DeleteOutlineOutlined
                  cursor="pointer"
                  onClick={() => {
                    this.setState(
                      {
                        imageThree: { url: "", file: undefined },
                        disabledImage3: false,
                        loadingImage3: false,
                      },
                      () => {
                        this.deletedImage(this.state.urlThree, 2);
                      }
                    );
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    );
  }

  onPressMealTime = (item, index) => {
    let mealTimeUpdate = this.state.mealTime;
    if (mealTimeUpdate.length == mealTimeConst.length) {
      mealTimeUpdate[index].checked = !mealTimeUpdate[index]?.checked;
    } else {
      for (let i = mealTimeUpdate.length; i < mealTimeConst.length; i++) {
        mealTimeUpdate.push(mealTimeConst[i]);
      }
      mealTimeUpdate[index].checked = true;
    }
    this.setState({ mealTime: mealTimeUpdate });
  };

  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(e) {
    let numberValue = Number(e);
    this.setState({ rowsPerPage: numberValue, page: 0 });
  }

  addIngredient() {
    let temp = this.state.newArrayIngredients;
    temp.push({ name: "", quantity: "" });
    this.setState({ newArrayIngredients: temp });
  }

  delNewIngreidient(e, i) {
    let temp = this.state.newArrayIngredients;
    temp.splice(i, 1);
    this.setState({ newArrayIngredients: temp });
    console.log(i);
  }

  changeName(value, i) {
    let temp = this.state.newArrayIngredients;
    temp[i].name = value;
    this.setState({ newArrayIngredients: temp });
  }

  changeQuantity(value, i) {
    let temp = this.state.newArrayIngredients;
    temp[i].quantity = value;
    this.setState({ newArrayIngredients: temp });
  }

  addStep() {
    let temp = this.state.newArraySteps;
    temp.push("");
    this.setState({ newArraySteps: temp });
  }

  delNewStep(e, i) {
    let temp = this.state.newArraySteps;
    temp.splice(i, 1);
    this.setState({ newArraySteps: temp });
  }

  changeStep(value, i) {
    let temp = this.state.newArraySteps;
    temp[i] = value;
    this.setState({ newArraySteps: temp });
  }
  //------------------------------------------------------------------
  addIngredientEdit() {
    let temp = this.state.editArrayIngredients;
    temp.push({ name: "", quantity: "" });
    this.setState({ editArrayIngredients: temp });
  }

  delEditIngreidient(e, i) {
    let temp = this.state.editArrayIngredients;
    temp.splice(i, 1);
    this.setState({ editArrayIngredients: temp });
    console.log(i);
  }

  changeNameEdit(value, i) {
    let temp = this.state.editArrayIngredients;
    temp[i].name = value;
    this.setState({ editArrayIngredients: temp });
  }

  changeQuantityEdit(value, i) {
    let temp = this.state.editArrayIngredients;
    temp[i].quantity = value;
    this.setState({ editArrayIngredients: temp });
  }

  addStepEdit() {
    let temp = this.state.editArraySteps;
    temp.push("");
    this.setState({ editArraySteps: temp });
  }

  delEditStep(e, i) {
    let temp = this.state.editArraySteps;
    temp.splice(i, 1);
    this.setState({ editArraySteps: temp });
  }

  changeStepEdit(value, i) {
    let temp = this.state.editArraySteps;
    temp[i] = value;
    this.setState({ editArraySteps: temp });
  }

  cancelAddRecipe() {
    this.setState({
      newArrayIngredients: [],
      newArraySteps: [],
      recipeDialog: false,
    });
  }

  cancelEditRecipe() {
    let temp = this.state.dishes[this.state.dishIndex].ingredients;
    let tempSteps = this.state.dishes[this.state.dishIndex].recipeSteps;
    this.setState({
      editArrayIngredients: temp,
      editArraySteps: tempSteps,
      recipeDialog: false,
    });
  }

  renderDialogRecipe() {
    let currentDialog = this.state.currentDialog;
    return (
      <DialogContent dividers={true}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Ingredients</Typography>
          <AddCircle
            onClick={() =>
              currentDialog === "newDish"
                ? this.addIngredient()
                : this.addIngredientEdit()
            }
          ></AddCircle>
        </Box>
        <Divider style={{ height: 15, backgroundColor: "transparent" }} />
        <Box>
          {currentDialog === "newDish" ? (
            <Box>
              {this.state.newArrayIngredients.map((e, i) => (
                <Box
                  key={i}
                  display="flex"
                  justifyContent="space-between"
                  style={{ marginBottom: 10 }}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    label={`Ingredient`}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={e.name}
                    onChange={(e) => this.changeName(e.target.value, i)}
                  />
                  <TextField
                    size="small"
                    variant="outlined"
                    label={`Quantity`}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <RemoveCircle
                            onClick={() => this.delNewIngreidient(e, i)}
                          />
                        </InputAdornment>
                      ),
                    }}
                    value={e.quantity}
                    onChange={(e) => this.changeQuantity(e.target.value, i)}
                  />
                </Box>
              ))}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Steps</Typography>
                <AddCircle onClick={() => this.addStep()}></AddCircle>
              </Box>
              <Divider style={{ height: 15, backgroundColor: "transparent" }} />
              {this.state.newArraySteps.map((e, i) => (
                <Box display="flex" alignItems="center">
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    size="small"
                    variant="outlined"
                    label={`${i + 1}`}
                    style={{ marginBottom: 15 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <RemoveCircle onClick={() => this.delNewStep(e, i)} />
                        </InputAdornment>
                      ),
                    }}
                    value={e}
                    onChange={(e) => this.changeStep(e.target.value, i)}
                  />
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              {this.state.editArrayIngredients != null && (
                <Box>
                  {this.state.editArrayIngredients.map((e, i) => (
                    <Box
                      key={i}
                      display="flex"
                      justifyContent="space-between"
                      style={{ marginBottom: 10 }}
                    >
                      <TextField
                        size="small"
                        variant="outlined"
                        label={`Ingredient`}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={e.name}
                        onChange={(e) => this.changeNameEdit(e.target.value, i)}
                      />
                      <TextField
                        size="small"
                        variant="outlined"
                        label={`Quantity`}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <RemoveCircle
                                onClick={() => this.delEditIngreidient(e, i)}
                              />
                            </InputAdornment>
                          ),
                        }}
                        value={e.quantity}
                        onChange={(e) =>
                          this.changeQuantityEdit(e.target.value, i)
                        }
                      />
                    </Box>
                  ))}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6">Steps</Typography>
                    <AddCircle onClick={() => this.addStepEdit()}></AddCircle>
                  </Box>
                  <Divider
                    style={{ height: 15, backgroundColor: "transparent" }}
                  />
                  {this.state.editArraySteps.map((e, i) => (
                    <Box display="flex" alignItems="center" key={i}>
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        size="small"
                        variant="outlined"
                        label={`${i + 1}`}
                        style={{ marginBottom: 15 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <RemoveCircle
                                onClick={() => this.delEditStep(e, i)}
                              />
                            </InputAdornment>
                          ),
                        }}
                        value={e}
                        onChange={(e) => this.changeStepEdit(e.target.value, i)}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Divider style={{ height: 30, backgroundColor: "transparent" }} />
      </DialogContent>
    );
  }

  searchFilter(text) {
    this.setState({ keyword: text });
    let newData = this.arrayholder.filter((element) => {
      let itemData = `${element.name.toUpperCase()}`;
      let textData = text.toUpperCase();
      itemData = itemData
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trimRight();
      textData = textData
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trimRight();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({ dishes: newData });
  }

  deleteDish() {
    axios
      .delete(
        parseURL +
          "classes/Dish/" +
          this.state.dishes[this.state.dishIndex].objectId,
        {
          headers: {
            "X-Parse-Application-Id": appId,
          },
        }
      )
      .then((response) => {
        console.log(response);
        this.setState({
          openDialog: false,
          dishIndex: 0,
          dialogAlert: false,
          currentDialog: "newDish",
        });
        this.getDishes();
      })
      .catch((error) => {
        console.log(error);
        this.setState({ openDialog: false });
      })
      .finally(() => {
        this.setState({ isThinking: false });
      });
  }

  deleteRecipe() {
    let params = {
      ingredients: [],
      recipeSteps: [],
      hasRecipe: false,
    };
    axios
      .put(
        parseURL +
          "classes/Dish/" +
          this.state.dishes[this.state.dishIndex].objectId,
        params,
        {
          headers: {
            "X-Parse-Application-Id": appId,
          },
        }
      )
      .then((response) => {
        console.log(response);
        this.setState({
          openDialog: false,
          dishIndex: 0,
          dialogAlert: false,
          recipeDialog: false,
          currentDialog: "newDish",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ openDialog: false });
      })
      .finally(() => {
        this.setState({ isThinking: false });
        this.getDishes();
      });
  }

  render() {
    return (
      <Box style={{ paddingLeft: 90 }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ backgroundColor: "blue", padding: 10 }}
        >
          <Button
            variant="contained"
            onClick={() => this.props.history.push("/dashboard")}
          >
            Atras
          </Button>
          <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
          <Button
            variant="contained"
            onClick={() =>
              this.setState({ openDialog: true, currentDialog: "newDish" })
            }
          >
            add dish
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontWeight: "600",
              fontSize: 12,
              textTransform: "uppercase",
              marginLeft: 10,
              marginTop: 10,
            }}
          >
            Dishes
          </Typography>
          <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
          <TextField
            style={{ marginTop: 10, marginRight: 10 }}
            size="small"
            variant="outlined"
            label="Keyword"
            value={this.state.keyword}
            onChange={(text) => this.searchFilter(text.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
        {this.state.dishes != null && (
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Calories</TableCell>
                  <TableCell> Portion</TableCell>
                  <TableCell>Recipe</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.dishes
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((item, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {item.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.calories}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.isPortion === false ? (
                          <IconButton>
                            <Cancel style={{ color: colors.muted }} />
                          </IconButton>
                        ) : (
                          <IconButton>
                            <CheckCircle style={{ color: colors.success }} />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.hasRecipe === false ? (
                          <IconButton>
                            <Cancel style={{ color: colors.muted }} />
                          </IconButton>
                        ) : (
                          <IconButton>
                            <CheckCircle style={{ color: colors.success }} />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <IconButton
                          onClick={() =>
                            this.setState(
                              {
                                currentDialog: "editDish",
                                dishIndex:
                                  this.state.page === 0
                                    ? i
                                    : this.state.rowsPerPage * this.state.page +
                                      i,
                                openDialog: true,
                                editIsPortion: item.isPortion,
                                editDishName: item.name,
                                editDishDetails: item.details,
                                editHasRecipe: item.hasRecipe,
                                editVeggieOption: item.isVeggieOption,
                                mealTime:
                                  item.mealTime === undefined
                                    ? JSON.parse(JSON.stringify(mealTimeConst))
                                    : JSON.parse(JSON.stringify(item.mealTime)),
                                editArrayIngredients:
                                  item.ingredients === undefined
                                    ? []
                                    : JSON.parse(
                                        JSON.stringify(item.ingredients)
                                      ),
                                editArraySteps:
                                  item.recipeSteps === undefined
                                    ? []
                                    : JSON.parse(
                                        JSON.stringify(item.recipeSteps)
                                      ),
                                urlOne:
                                  item.images === undefined
                                    ? undefined
                                    : item.images[0],
                                urlTwo:
                                  item.images === undefined
                                    ? undefined
                                    : item.images[1],
                                urlThree:
                                  item.images === undefined
                                    ? undefined
                                    : item.images[2],
                              },
                              () => [
                                console.log(
                                  this.state.urlOne,
                                  this.state.urlTwo,
                                  this.state.urlThree
                                ),
                                this.nuevafun(),
                              ]
                            )
                          }
                        >
                          <PlayCircleFilled />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={rowsPerPage}
              component="div"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              count={this.state.dishes.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={(e, n) => this.handleChangePage(e, n)}
              onChangeRowsPerPage={(e) =>
                this.handleChangeRowsPerPage(e.target.value)
              }
            />
          </TableContainer>
        )}
        <Divider style={{ height: 20, backgroundColor: "transparent" }} />
        <Dialog open={this.state.openDialog} fullWidth={true} maxWidth={"sm"}>
          <DialogTitle
            disableTypography
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>Add Dish</Typography>
            <IconButton
              aria-label="close"
              onClick={() =>
                this.setState({
                  openDialog: false,
                  currentDialog: "newDish",
                  mealTime: JSON.parse(JSON.stringify(mealTimeConst)),
                })
              }
            >
              <Cancel />
            </IconButton>
          </DialogTitle>
          {this.renderDialog()}
          <DialogActions
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{
                color:
                  this.state.currentDialog === "newDish" ? colors.muted : "red",
              }}
              disabled={this.state.currentDialog === "newDish" ? true : false}
              onClick={() =>
                this.setState({ dialogAlert: true, deleteType: "dish" })
              }
            >
              Delete
            </Button>
            <Button
              onClick={() =>
                this.state.currentDialog === "newDish"
                  ? this.postNewDish()
                  : this.putEditDish()
              }
              color="primary"
            >
              Accept
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.recipeDialog}
          scroll="paper"
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle
            disableTypography
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>Recipe</Typography>
            <IconButton
              aria-label="close"
              onClick={() =>
                this.state.currentDialog === "newDish"
                  ? this.cancelAddRecipe()
                  : this.cancelEditRecipe()
              }
            >
              <Cancel />
            </IconButton>
          </DialogTitle>
          {this.renderDialogRecipe()}
          <DialogActions
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{
                color:
                  this.state.currentDialog === "newDish" ? colors.muted : "red",
              }}
              disabled={this.state.currentDialog === "newDish" ? true : false}
              onClick={() =>
                this.setState({ dialogAlert: true, deleteType: "recipe" })
              }
            >
              Delete
            </Button>
            <Button
              onClick={() => this.setState({ recipeDialog: false })}
              color="primary"
            >
              Accept
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.dialogAlert}>
          <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
          <DialogContent>
            <Typography>Are you sure to delete it</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogAlert: false })}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                this.state.deleteType === "recipe"
                  ? this.deleteRecipe()
                  : this.deleteDish()
              }
              color="primary"
              autoFocus
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          key={"top" + "right"}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={4000}
          open={this.state.snackImage}
          onClose={() => this.setState({ snackImage: false })}
        >
          <Alert
            onClose={() => this.setState({ snackImage: false })}
            severity={this.state.alertType === true ? "success" : "warning"}
            variant="filled"
          >
            {this.state.alertType === true
              ? "La imagen se subio con exito"
              : "Los sentimos, la imgaen no se pudo subir, intentelo mas tarde"}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

export default Meals;

const dishPortions = {
  cereals: {
    name: "Cereals",
    _id: "FTXvmJR0iY",
    color: "#ffc41e",
    value: 0,
  },
  protein: {
    name: "Protein",
    _id: "naq38iCrpg",
    color: "#fb5753",
    value: 0,
  },
  vegetables: {
    name: "Vegetables",
    _id: "X3EU29CGbd",
    color: "#29ab60",
    value: 0,
  },
  fruits: {
    name: "Fruits",
    _id: "rvDflv32V7",
    color: "#fd3d16",
    value: 0,
  },
  dairy: {
    name: "Dairy",
    _id: "2GhQ5T4MfT",
    color: "#1d3a76",
    value: 0,
  },
  legumes: {
    name: "Legumes",
    _id: "a9XtMSpa7M",
    color: "#f0537f",
    value: 0,
  },
  oilsAndFats: {
    name: "Oils and Fats",
    _id: "8J0JiF6C9j",
    color: "#737877",
    value: 0,
  },
  calorieFree: {
    name: "Calorie Free",
    _id: "GHAUm8i0KC",
    color: "#553166",
    value: 0,
  },
};

const mealTimeConst = [
  {
    id: "breakfast",
    checked: false,
  },
  {
    id: "lunch",
    checked: false,
  },
  {
    id: "dinner",
    checked: false,
  },
  {
    id: "snack",
    checked: false,
  },
  {
    id: "addOn",
    checked: false,
  },
  {
    id: "salads",
    checked: false,
  },
  {
    id: "garrison",
    checked: false,
  },
];
