import React, { Component } from "react";
import { parseURL, appId } from "../resources/variables";
import axios from "axios";
import moment from "moment";
import { Typography, Grid } from "@material-ui/core/";

export class UserDetails extends Component {
  state = {
    photoProofs: null,
    userChallenges: null,
  };

  componentDidMount() {
    const params = this.props.objectId;
    this.getPhotos(params);
    this.getChallenges(params);
  }

  getPhotos(objectId) {
    let queryParams = JSON.stringify({
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: objectId,
      },
    });
    axios
      .get(parseURL + "classes/PhotoProof/?where=" + queryParams, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        this.setState({ photoProofs: res.data.results.length }, () => {
          console.log(this.state.photoProofs);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getChallenges(objectId) {
    let queryParams = JSON.stringify({
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: objectId,
      },
    });
    axios
      .get(parseURL + "classes/UserChallenge/?where=" + queryParams, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        this.setState({ userChallenges: res.data.results.length }, () => {
          console.log(this.state.userChallenges);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderValue(item) {
    let value = "";
    switch (item) {
      case "userName":
        value = this.props.user.username;
        break;
      case "email":
        value = this.props.user.email;
        break;
      case "sex":
        value = "N/A";
        break;
      case "goals":
        value = "Live healthy";
        break;
      case "deviceCountry":
        value = this.props.user.deviceCountry;
        break;
      case "deviceLocale":
        value = this.props.user.deviceLocale;
        break;
      case "systemVersion":
        value = this.props.user.systemVersion;
        break;
      case "photoProofs":
        value = this.state.photoProofs;
        break;
      case "userChallenges":
        value = this.state.userChallenges;
        break;
      case "premium":
        switch (this.props.user.isPremium) {
          case true:
            value = "True";
            break;
          case false:
            value = "False";
            break;
          default:
            break;
        }
        break;
      case "subscription":
        value = this.props.user.subscription;
        break;
      case "expiresAt":
        value = moment(this.props.user.expiresAt.iso)
          .local()
          .format("YYYY-MM-DD");
        break;
      default:
        break;
    }
    return value;
  }
  render() {
    return (
      <Grid container>
        {userDetails.map((item, i) => (
          <Grid item xs={4} key={i}>
            <Typography variant="subtitle1">
              {item.title}: {this.renderValue(item.item)}{" "}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default UserDetails;

let userDetails = [
  {
    title: "Username",
    item: "userName",
  },
  {
    title: "Email",
    item: "email",
  },
  {
    title: "Sex",
    item: "sex",
  },
  {
    title: "Goals",
    item: "goals",
  },
  {
    title: "Device country",
    item: "deviceCountry",
  },
  {
    title: "Device Locale",
    item: "deviceLocale",
  },
  {
    title: "System Version",
    item: "systemVersion",
  },
  {
    title: "Photo proofs",
    item: "photoProofs",
  },
  {
    title: "User Challenges",
    item: "userChallenges",
  },
  {
    title: "Premium",
    item: "premium",
  },
];
