import React, { Component } from "react";
import axios from "axios";
import { FileCopy } from "@material-ui/icons/";
import { Alert } from "@material-ui/lab";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
} from "@material-ui/core/";

import { parseURL, appId } from "../resources/variables";
import { CSVLink, CSVDownload } from "react-csv";

let forms = require("../resources/forms.json");
//let users = require("../resources/save_file.json");
//let tsn_data = require("../resources/file.json");
let tsn_users = require("../resources/file.json");

class Dashboard extends Component {
  state = {
    csvData: [],
    link: "",
    email: "",
    payId: "",
    name: "",
    phone: "",
    helperPayId: false,
    helperEmail: false,
    openSnack: false,
  };

  componentDidMount() {
    //this.loadMails();
    //this.getEquivalents();
    //this.gravityExtract();
    //this.formatDataTSN();
    //this.extract_bdays();
    //this.journey_details();
  }

  /* loadMails() {
    let constraints = JSON.stringify({
      newsletter: { $ne: false },
    });
    axios
      .get(parseURL + "users/?where=" + constraints + "&limit=1", {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data);
        let array = [];
        array.push(csvHeaders);
        res.data.results.forEach((element) => {
          array.push([element.email, "es"]);
        });
        console.log(array);
        this.setState({ csvData: array });
      })
      .catch((err) => {
        console.log(err);
      });
  } */

  /* getEquivalents() {
    axios
      .get(parseURL + "classes/Equivalent?order=-createdAt&limit=1000", {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        res.data.results.forEach((element) => {
          this.fixEquivalent(element.objectId, element.name);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  } */

  /* fixEquivalent(id, name) {
    axios
      .put(
        parseURL + "classes/Equivalent/" + id,
        { title: JSON.stringify(name) },
        {
          headers: {
            "X-Parse-Application-Id": appId,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  } */

  // gravityExtract() {
  //   // forms.forEach((element) => {
  //   //   console.log(element);
  //   // });
  //   let newArray = [];
  //   for (const [key, value] of Object.entries(forms)) {
  //     console.log(value);
  //     if (
  //       [91, 93, 94, 98, 99, 85, 86, 81, 87, 89, 90, 95, 96, 100].includes(
  //         value.id
  //       )
  //     ) {
  //       let obj = {};
  //       obj.title = value.title;
  //       obj.id = value.id;
  //       obj.fields = [];
  //       value.fields.forEach((element) => {
  //         let newObj = {
  //           label: element.label,
  //           adminLabel: element.adminLabel,
  //         };
  //         if (element.choices != "") {
  //           element.choices.forEach((choice) => {
  //             delete choice.isSelected;
  //             delete choice.price;
  //           });
  //           newObj.choices = element.choices;
  //         }
  //         obj.fields.push(newObj);
  //       });
  //       newArray.push(obj);
  //     }
  //   }
  //   console.log(JSON.stringify(newArray));
  // }

  // async formatDataTSN() {
  //   let array = users.users;
  //   let newString = "";

  //   let signInWithApple = 0;
  //   let signInWithGoogle = 0;
  //   let signInWithMail = 0;
  //   let signInWithMultiple = 0;

  //   array.forEach((element, index) => {
  //     let newObj = {};

  //     newObj._id = element.localId;

  //     // newObj._wperm = [
  //     //   element.localId
  //     // ];

  //     // newObj._rperm = [
  //     //   "*",
  //     //   element.localId
  //     // ],

  //     // newObj._acl= {
  //     //   [element.localId]: {
  //     //     "w": true,
  //     //     "r": true
  //     //   },
  //     //   "*": {
  //     //     "r": true
  //     //   }
  //     // }

  //     newObj.username = element.email;
  //     newObj.email = element.email;
  //     newObj.emailVerified = element.emailVerified;
  //     newObj._hashed_password =
  //       "$2b$10$94Vf7IQHFKuYVT7gGCWhPeDDerIXnPpGqe2ejbX6R925r6bn8Y1AO";

  //     let dateCreated = new Date(Number(element.createdAt)).getTime();
  //     dateCreated = new Date(dateCreated).toISOString();
  //     newObj._created_at = { $date: dateCreated };

  //     let dateUpdated = new Date(Number(element.lastSignedInAt)).getTime();
  //     dateUpdated = new Date(dateUpdated).toISOString();
  //     newObj._updated_at = { $date: dateUpdated };

  //     if (element?.photoUrl) {
  //       newObj.details = { profileImage: element.photoUrl };
  //     }

  //     newObj.migrationRequired = true;

  //     newString = newString.concat(JSON.stringify(newObj), "\n");

  //   //   if(element?.passwordHash){
  //   //     signInWithMail = signInWithMail + 1;
  //   //   }else if (element.providerUserInfo[0]?.providerId == 'google.com'){
  //   //     signInWithGoogle = signInWithGoogle + 1;
  //   //   }else{
  //   //     signInWithApple = signInWithApple + 1
  //   //     //console.log(element.email)
  //   //   }

  //   //   if (element.providerUserInfo.length > 0 && element?.passwordHash){
  //   //     signInWithMultiple = signInWithMultiple + 1
  //   //     console.log(element.email)
  //   //   }

  //   });

  //   // console.log(
  //   //   signInWithApple,
  //   //   signInWithGoogle,
  //   //   signInWithMail,
  //   //   signInWithMultiple
  //   // );

  //   // AUTH SHIT
  //   //   if (index < 5) {
  //   //     axios({
  //   //       method: "POST",
  //   //       url:
  //   //         "https://identitytoolkit.googleapis.com/v1/accounts:createAuthUri",
  //   //       params: {
  //   //         key: "AIzaSyD94mVLLjVTOnWmwi8ixFBy3CV7J7E1Y_A",
  //   //       },
  //   //       headers: {
  //   //         "Content-Type": "application/json; charset=utf-8",
  //   //       },
  //   //       data: {
  //   //         continueUri: "http://localhost:8080/app",
  //   //         identifier: element.email,
  //   //       },
  //   //     })
  //   //       .then((response) => {
  //   //         if (response.data?.signinMethods) {
  //   //           console.log(element.email, response.data?.signinMethods[0]);
  //   //           switch (response.data?.signinMethods[0]) {
  //   //             case "password":
  //   //               signInWithMail = signInWithMail + 1;
  //   //               break;

  //   //             case "apple.com":
  //   //               signInWithApple = signInWithApple + 1;
  //   //               break;

  //   //             case "google.com":
  //   //               signInWithApple = signInWithApple + 1;
  //   //               break;
  //   //           }
  //   //         }
  //   //         console.log(
  //   //           signInWithApple,
  //   //           signInWithGoogle,
  //   //           signInWithMail,
  //   //           signInWithMultiple
  //   //         );
  //   //       })
  //   //       .catch((error) => {
  //   //         console.log(error.response.data);
  //   //       });
  //   //   }
  //   // });

  //   const element = document.createElement("a");
  //   const file = new Blob([newString], {type: 'text/plain'});
  //   element.href = URL.createObjectURL(file);
  //   element.download = "myFile.txt";
  //   document.body.appendChild(element); // Required for this to work in FireFox
  //   element.click();
  // }

  extract_bdays() {
    let years = {};
    let counter1919 = 0;
    let counter1920 = 0;
    let counter1930 = 0;
    let counter1940 = 0;
    let counter1950 = 0;
    let counter1960 = 0;
    let counter1970 = 0;
    let counter1980 = 0;
    let counter1990 = 0;
    let counter2000 = 0;
    let counter2010 = 0;
    let counter2020 = 0;
    let counter = 0;
    tsn_users.forEach((element) => {
      //console.log(element.details.birthday.substring(0, 4));
      if (element.details.birthday) {
        let year = element.details.birthday.substring(0, 4);
        if (year < 1920) {
          counter1919 += 1;
        } else if (year < 1930) {
          counter1920 += 1;
        } else if (year < 1940) {
          counter1930 += 1;
        } else if (year < 1950) {
          counter1940 += 1;
        } else if (year < 1960) {
          counter1950 += 1;
        } else if (year < 1970) {
          counter1960 += 1;
        } else if (year < 1980) {
          counter1970 += 1;
        } else if (year < 1990) {
          counter1980 += 1;
        } else if (year < 2000) {
          counter1990 += 1;
        } else if (year < 2010) {
          counter2000 += 1;
        } else if (year < 2020) {
          counter2010 += 1;
        } else if (year < 2030) {
          counter2020 += 1;
        }
        counter += 1;
      }
      // if (["gift"].includes(element.subscriptionType)) {
      //   if (years[element.details.birthday.substring(0, 4)]) {
      //     years[element.details.birthday.substring(0, 4)] += 1;
      //   } else {
      //     years[element.details.birthday.substring(0, 4)] = 1;
      //   }
      //   counter += 1;
      // }
    });
    console.log(counter);
    console.log("OG", counter1919);
    console.log("1920s", counter1920);
    console.log("1930s", counter1930);
    console.log("1940s", counter1940);
    console.log("1950s", counter1950);
    console.log("1960s", counter1960);
    console.log("1970s", counter1970);
    console.log("1980s", counter1980);
    console.log("1990s", counter1990);
    console.log("2000s", counter2000);
    console.log("2010s", counter2010);
    console.log("2020s", counter2020);
    //console.log(JSON.stringify(years));
  }

  // journey_details() {
  //   let users = [];
  //   let completed = 0;
  //   let less = 0;
  //   tsn_data.forEach((element) => {
  //     if (users.findIndex((item) => item === element?.user?.objectId) === -1) {
  //       users.push(element.user?.objectId || null);
  //     }
  //     if (element.steps.length === 11) {
  //       completed = completed + 1;
  //     }
  //     if (element.steps.length < 5) {
  //       less = less + 1;
  //     }
  //     console.log(element.steps.length);
  //   });
  //   console.log(tsn_data.length);
  //   console.log(users.length);
  //   console.log(completed);
  //   console.log(less);
  // }

  validateData() {
    let newString = `https://calendly.com/nutrigeek/consulta-online`;
    if (
      this.state.email === null ||
      this.state.email === " " ||
      this.state.email === ""
    ) {
      this.setState({ helperEmail: true, helperPayId: false });
    } else if (
      this.state.payId === null ||
      this.state.payId === " " ||
      this.state.payId === ""
    ) {
      this.setState({ helperEmail: false, helperPayId: true });
    } else {
      this.setState({ helperEmail: false, helperPayId: false });
      newString = `${newString}?email=${this.state.email}&a2=${this.state.payId}`;
      if (this.state.phone !== "") {
        newString = `${newString}&a1=${this.state.phone}`;
      }
      if (this.state.name !== "") {
        newString = `${newString}&name=${this.state.name}`;
      }
      this.setState({ link: encodeURI(newString) });
    }
  }

  copyToClipboard() {
    const copyText = document.getElementById("text_to_copy");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    this.setState({ openSnack: true });
  }

  handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnack: false });
  }

  render() {
    return (
      <Box>
        <Grid container direction="row" justify="center">
          NutriAdmin 0.1.1
          {/* <CSVLink data={this.state.csvData}>Download me</CSVLink>; */}
        </Grid>
        <Divider style={{ height: 30, backgroundColor: "transparent" }} />
        <Grid
          container
          style={{ paddingLeft: 50, paddingRight: 50 }}
          // direction="row"
          // justify="space-around"
          // alignItems="center"
        >
          <Grid md={4} item></Grid>
          <Grid md={4} item></Grid>
          <Grid md={4} item>
            <Paper elevation={3} style={{ padding: 15 }}>
              <Typography variant="h6">Generate Calendly Link</Typography>
              <Divider style={{ height: 20, backgroundColor: "transparent" }} />
              <TextField
                fullWidth
                label="Email"
                style={styles.textField}
                error={this.state.helperEmail === true ? true : false}
                helperText={
                  this.state.helperEmail === true &&
                  "This field can not be blank."
                }
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <TextField
                fullWidth
                label="Pay id"
                style={styles.textField}
                error={this.state.helperPayId === true ? true : false}
                helperText={
                  this.state.helperPayId === true &&
                  "This field can not be blank."
                }
                onChange={(e) => this.setState({ payId: e.target.value })}
              />
              <TextField
                fullWidth
                label="Name"
                style={styles.textField}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
              <TextField
                fullWidth
                label="Phone"
                style={styles.textField}
                onChange={(e) => this.setState({ phone: e.target.value })}
              />
              <Divider style={{ height: 20, backgroundColor: "transparent" }} />
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => this.validateData()}
              >
                Create Link
              </Button>
              <Divider style={{ height: 30, backgroundColor: "transparent" }} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <TextField
                  fullWidth
                  multiline={true}
                  id="text_to_copy"
                  value={this.state.link}
                />
                <FileCopy
                  style={{ marginLeft: 10 }}
                  cursor="pointer"
                  onClick={() => this.copyToClipboard()}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Snackbar
          key={"top" + "right"}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={4000}
          open={this.state.openSnack}
          onClose={() => this.setState({ openSnack: false })}
        >
          <Alert
            severity="success"
            variant="filled"
            elevation={6}
            onClose={() => this.setState({ openSnack: false })}
          >
            The link has been copied to the clipboard.
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

export default Dashboard;

const styles = {
  textField: {
    marginBottom: 15,
  },
};
