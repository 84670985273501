export function renderMealTime(item) {
  switch (item) {
    case "breakfast":
      return ["Breakfast", "Breakfast"];
    case "earlySnack":
      return ["Snack", "Early Snack"];
    case "lunch":
      return ["Lunch", "Lunch"];
    case "lateSnack":
      return ["Snack", "Late Snack"];
    case "dinner":
      return ["Dinner", "Dinner"];
    case "workoutSnack":
      return ["Snack", "Workout Snack"];
    default:
      break;
  }
}

export function renderMealTimeDishes(item) {
  switch (item) {
    case "breakfast":
      return "Breakfast";
    case "lunch":
      return "Lunch";
    case "dinner":
      return "Dinner";
    case "snack":
      return "Snack";
    case "addOn":
      return "Add On";
    case "salads":
      return "Salads";
    case "garrison":
      return "Garrison";
    default:
      break;
  }
}

export function renderEquivalentColour(item) {
  switch (item) {
    case "a9XtMSpa7M":
      return "#f0537f";
    case "X3EU29CGbd":
      return "#29ab60";
    case "rvDflv32V7":
      return "#fd3d16";
    case "FTXvmJR0iY":
      return "#ffc41e";
    case "naq38iCrpg":
      return "#fb5753";
    case "2GhQ5T4MfT":
      return "#1d3a76";
    case "8J0JiF6C9j":
      return "#737877";
    case "GHAUm8i0KC":
      return "##553166";
    default:
      break;
  }
}
