import React, { Component } from "react";
import axios from "axios";
import { parseURL, appId, rowsPerPage } from "../resources/variables";
import { Cancel, Search, Edit, MoreHoriz } from "@material-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core/";

export class Categories extends Component {
  state = {
    taskCategories: null,
    keyword: "",
    page: 0,
    rowsPerPage: 10,
    openDialog: false,
    menuOpen: false,
    alertDialog: false,
    anchorEl: undefined,
    currentDialog: "editTaskCategory",
    //Edit, Clone or New TaskCategory
    taskCategoryObjectId: "",
    titleEn: "",
    titleEs: "",
    character: "",
  };

  componentDidMount() {
    this.arrayHolderForSearchByKeyword = [];
    this.getTaskCategory();
  }

  getTaskCategory() {
    axios
      .get(parseURL + "classes/TaskCategory?order=-createdAt&limit=1000", {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        this.setState({ taskCategories: res.data.results });
        this.arrayHolderForSearchByKeyword = res.data.results;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  createNewTaskCategory() {
    this.setState({
      currentDialog: "newTaskCategory",
      titleEn: "",
      titleEs: "",
      character: "",
      openDialog: true,
    });
  }

  searchFilter(text) {
    this.setState({ keyword: text });
    let newData = this.arrayHolderForSearchByKeyword.filter((element) => {
      let itemData = `${element.title.en.toUpperCase()}`;
      let textData = text.toUpperCase();
      itemData = itemData
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trimRight();
      textData = textData
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trimRight();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({ taskCategories: newData });
  }

  renderDialog() {
    let currentDialog = this.state.currentDialog;
    return (
      <DialogContent>
        <Box display="flex" justifyContent="space-between">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label="Title 🇺🇸"
            defaultValue={
              currentDialog === "newTaskCategory" ? null : this.state.titleEn
            }
            onChange={(e) => this.setState({ titleEn: e.target.value })}
          />
          <Divider style={{ width: 10, backgroundColor: "transparent" }} />
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label="Title 🇲🇽"
            defaultValue={
              currentDialog === "newTaskCategory" ? null : this.state.titleEs
            }
            onChange={(e) => this.setState({ titleEs: e.target.value })}
          />
        </Box>
        <Divider style={{ height: 15, backgroundColor: "transparent" }} />
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label="Icon"
          defaultValue={
            currentDialog === "newTaskCategory" ? null : this.state.character
          }
          onChange={(e) => this.setState({ character: e.target.value })}
        />
      </DialogContent>
    );
  }

  handleChangeRowsPerPage(e) {
    let numberValue = Number(e);
    this.setState({ rowsPerPage: numberValue, page: 0 });
  }

  putTaskCategory() {
    let params = {
      title: {
        en: this.state.titleEn,
        es: this.state.titleEs,
      },
      character: this.state.character,
    };
    axios
      .put(
        parseURL + "classes/TaskCategory/" + this.state.taskCategoryObjectId,
        params,
        {
          headers: {
            "X-Parse-Application-Id": appId,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          menuOpen: false,
          openDialog: false,
        });
      });
    this.getTaskCategory();
  }

  cloneOrPostTaskCategory() {
    let params = {
      title: {
        en:
          this.state.currentDialog === "newTaskCategory"
            ? this.state.titleEn
            : `${this.state.titleEn} (copy)`,
        es:
          this.state.currentDialog === "newTaskCategory"
            ? this.state.titleEs
            : `${this.state.titleEs} (copy)`,
      },
      character: this.state.character,
    };
    axios
      .post(parseURL + "classes/TaskCategory", params, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          menuOpen: false,
          openDialog: false,
        });
      });
    this.getTaskCategory();
  }

  deleteTaskCategory() {
    axios
      .delete(
        parseURL + "classes/TaskCategory/" + this.state.taskCategoryObjectId,
        {
          headers: {
            "X-Parse-Application-Id": appId,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          menuOpen: false,
          openDialog: false,
          alertDialog: false,
        });
      });
    this.getTaskCategory();
  }

  render() {
    return (
      <Box style={{ paddingLeft: 90 }}>
        <Box
          display="flex"
          justifyContent="space-around"
          style={{ backgroundColor: "blue", padding: 10 }}
        >
          <Button
            variant="contained"
            onClick={() => this.props.history.push("/dashboard")}
          >
            Atras
          </Button>
          <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
          <Button
            variant="contained"
            onClick={() => this.createNewTaskCategory()}
          >
            Add Task Category
          </Button>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography
            style={{
              fontWeight: "600",
              fontSize: 12,
              textTransform: "uppercase",
              marginLeft: 10,
              marginTop: 10,
            }}
          >
            Categories
          </Typography>
          <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
          <TextField
            style={{ marginTop: 10, marginRight: 10 }}
            size="small"
            variant="outlined"
            label="Keyword"
            value={this.state.keyword}
            onChange={(text) => this.searchFilter(text.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {this.state.taskCategories === null ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Title En</TableCell>
                  <TableCell>Title Es</TableCell>
                  <TableCell> Icon</TableCell>
                  <TableCell> Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.taskCategories
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((item, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {item.title.en}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.title.es}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.character}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <IconButton
                          color="inherit"
                          onClick={() =>
                            this.setState({
                              currentDialog: "editTaskCategory",
                              taskCategoryObjectId: item.objectId,
                              titleEn: item.title.en,
                              titleEs: item.title.es,
                              character: item.character,
                              openDialog: true,
                            })
                          }
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={rowsPerPage}
              component="div"
              count={this.state.taskCategories.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={(event, newPage) =>
                this.setState({ page: newPage })
              }
              onChangeRowsPerPage={(e) =>
                this.handleChangeRowsPerPage(e.target.value)
              }
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            />
          </TableContainer>
        )}
        <Dialog open={this.state.openDialog} fullWidth={true} maxWidth={"sm"}>
          <DialogTitle
            disableTypography
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>
              {this.state.currentDialog === "newTaskCategory"
                ? "Add Task Category"
                : "Edit Task Category"}
            </Typography>
            <IconButton onClick={() => this.setState({ openDialog: false })}>
              <Cancel />
            </IconButton>
          </DialogTitle>
          {this.renderDialog()}
          <DialogActions
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              justifyContent: "space-between",
            }}
          >
            <Button
              disabled={
                this.state.currentDialog === "newTaskCategory" ? true : false
              }
              onClick={(e) =>
                this.setState({
                  menuOpen: true,
                  anchorEl: e.currentTarget,
                })
              }
            >
              <MoreHoriz />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                this.state.currentDialog === "newTaskCategory"
                  ? this.cloneOrPostTaskCategory()
                  : this.putTaskCategory()
              }
            >
              Accept
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
          anchorEl={this.state.anchorEl}
          getContentAnchorEl={null}
          open={this.state.menuOpen}
          onClose={() => this.setState({ menuOpen: false })}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MenuItem onClick={() => this.cloneOrPostTaskCategory()}>
            Clone
          </MenuItem>
          <MenuItem
            onClick={() => this.setState({ alertDialog: true })}
            style={{ color: "red" }}
          >
            Delete
          </MenuItem>
        </Menu>
        <Dialog open={this.state.alertDialog} fullWidth={true}>
          <DialogTitle>Alert</DialogTitle>
          <DialogContent>
            <Typography>Are you sure to delete it?</Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => this.setState({ alertDialog: false })}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.deleteTaskCategory()}
            >
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default Categories;
