import React, { Component } from "react";
import { Box, Typography } from "@material-ui/core/";

export class NewDiet extends Component {
  render() {
    return (
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ marginBottom: 15 }}
        >
          <Typography>Are you hungry?</Typography>
          <Typography style={{ color: "red" }}>
            {this.props.newDiet.hungry === true ? "Si" : "No"}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ marginBottom: 15 }}
        >
          <Typography>Was it too much food?</Typography>
          <Typography style={{ color: "red" }}>
            {this.props.newDiet.tooMuchFood === true ? "Si" : "No"}
          </Typography>
        </Box>
        <Box style={{ marginBottom: 15 }}>
          <Typography>What would you like your menu to include?</Typography>
          <Typography style={{ paddingLeft: 15, color: "red" }}>
            {this.props.newDiet.include}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ marginBottom: 15 }}
        >
          <Typography>Do you do the snacks?</Typography>
          <Typography style={{ color: "red" }}>
            {this.props.newDiet.makeSnacks === true ? "Si" : "No"}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ marginBottom: 15 }}
        >
          <Typography>What food do you spend the most time on?</Typography>
          <Typography style={{ paddingLeft: 15, color: "red" }}>
            {this.props.newDiet.spendMuchTime === 1
              ? "Desayuno"
              : this.props.newDiet.spendMuchTime === 2
              ? "Comida"
              : "Cena"}
          </Typography>
        </Box>
        <Box style={{ marginBottom: 15 }}>
          <Typography>At what meal time are you hungry?</Typography>
          {this.props.newDiet != null &&
            this.props.newDiet.hungryTime.map(
              (e, i) =>
                e.checked === true && (
                  <Typography style={{ paddingLeft: 15, color: "red" }}>
                    {e.title}
                  </Typography>
                )
            )}
        </Box>
        <Box style={{ marginBottom: 15 }}>
          <Typography>Do you have a specific craving?</Typography>
          <Typography style={{ paddingLeft: 20, color: "red" }}>
            {this.props.newDiet.whim}
          </Typography>
        </Box>
        <Box style={{ marginBottom: 15 }}>
          <Typography>
            Has anything in your routine/life changed? (shift and schedule
            change, more exercise, heavier training)
          </Typography>
          <Typography style={{ paddingLeft: 20, color: "red" }}>
            {this.props.newDiet.routine}
          </Typography>
        </Box>
        <Box style={{ marginBottom: 15 }}>
          <Typography>Do you have any other comments?</Typography>
          <Typography style={{ paddingLeft: 20, color: "red" }}>
            {this.props.newDiet.otherComment}
          </Typography>
        </Box>
      </Box>
    );
  }
}

export default NewDiet;
