import React, { Component } from "react";
import { Box, Grid } from "@material-ui/core/";

export class Challenges extends Component {
  render() {
    return (
      <Box style={{ paddingLeft: 90 }}>
        <Grid
          container
          style={{ backgroundColor: "#f8f9fc" }}
          direction="row"
          justify="center"
        >
          Challenges
        </Grid>
      </Box>
    );
  }
}

export default Challenges;