import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import {
  parseURL,
  appId,
  rowsPerPage,
  masterKey,
} from "../resources/variables";
import { colors } from "../styles/styles";
import { Search, ChevronRight, PlayCircleFilled } from "@material-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  FormGroup,
  Grid,
  InputAdornment,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core/";

class Diet extends Component {
  state = {
    isThinking: true,
    usersData: null,
    keyword: "",
    keywordTable: "",
    mails: null,
    diets: null,
    selectedDietOrSearch: 0,
    page: 0,
    rowsPerPage: 5,
  };

  componentDidMount() {
    this.arrayholder = [];
    this.getUsersData();
    this.getDiet();
  }

  getUsersData() {
    this.setState({ isThinking: true });
    axios
      .get(parseURL + 'users/?where={"dietStatus":{"$gte":1,"$lte":1}}', {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        this.setState({ usersData: res.data.results });
        this.setState({ isThinking: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isThinking: false });
      });
  }

  getDiet() {
    axios
      .get(parseURL + "classes/Diet?order=-createdAt&include=user", {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        this.setState({ diets: res.data.results });
        this.arrayholder = res.data.results;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getMailsKeyword(keyword) {
    axios
      .get(
        parseURL +
          `users/?where={
            "$or":[
              {"email":{"$regex":"${keyword}"}},
              {"code":"${keyword}"}
            ]
          }`,
        //`users/?where={"email":{"$regex":"${keyword}"}}`,
        {
          headers: {
            "X-Parse-Application-Id": appId,
            "X-Parse-Master-Key": masterKey,
          },
        }
      )
      .then((res) => {
        console.log(res.data.results);
        this.setState({ mails: res.data.results, isThinking: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isThinking: false });
      });
  }

  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(e) {
    let numberValue = Number(e);
    this.setState({ rowsPerPage: numberValue, page: 0 });
  }

  goToResults(dietId, patientId) {
    this.props.history.push("/results/" + patientId);
    localStorage.setItem("dietId", dietId);
  }

  searchFilter(text) {
    this.setState({ keywordTable: text });
    let newData = this.arrayholder.filter((element) => {
      let itemData = `${element.title?.toUpperCase()}`;
      let textData = text.toUpperCase();
      itemData = itemData
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trimRight();
      textData = textData
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trimRight();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({ diets: newData });
  }

  render() {
    return (
      <Box style={{ paddingLeft: 90 }}>
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          style={{ backgroundColor: colors.bg[200], padding: 10 }}
        >
          <Button variant="contained">Back</Button>
          <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
          <Button variant="contained">Save</Button>
        </Box>
        <Grid container>
          <Grid item md={8}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: 12,
                  textTransform: "uppercase",
                  marginLeft: 10,
                  marginTop: 10,
                }}
              >
                Diets
              </Typography>
              <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
              <TextField
                style={{ marginTop: 10 }}
                size="small"
                variant="outlined"
                label="Keyword"
                value={this.state.keywordTable}
                onChange={(text) => this.searchFilter(text.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {this.state.diets != null && (
              <TableContainer
                style={{
                  backgroundColor: colors.bg[200],
                  borderRadius: 10,
                  margin: 10,
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>UserId</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.diets
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map((item, i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            {item.title}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.user?.medicalHistory?.generalData.name ||
                              item.user?.name ||
                              item.user?.email}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.user?.objectId}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {moment(item.createdAt)
                              .local()
                              .format("MMM Do YYYY")}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <IconButton
                              onClick={() =>
                                this.goToResults(
                                  item.objectId,
                                  item.user.objectId
                                )
                              }
                            >
                              <PlayCircleFilled />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={this.state.diets.length}
                  rowsPerPageOptions={rowsPerPage}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={(e, n) => this.handleChangePage(e, n)}
                  onChangeRowsPerPage={(e) =>
                    this.handleChangeRowsPerPage(e.target.value)
                  }
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              </TableContainer>
            )}
          </Grid>
          <Grid item style={{ padding: 10 }} md={4}>
            <Box
              minHeight={500}
              style={{
                backgroundColor: colors.bg[200],
                borderRadius: 10,
                margin: "0 10px 0 10px",
                padding: 5,
              }}
            >
              {this.state.isThinking ? (
                <CircularProgress />
              ) : (
                <Box>
                  <ButtonGroup
                    fullWidth
                    variant="contained"
                    size="small"
                    color="primary"
                  >
                    <Button
                      onClick={() => this.setState({ selectedDietOrSearch: 1 })}
                    >
                      Search by mail or code
                    </Button>
                  </ButtonGroup>
                  <Box>
                    <Divider
                      style={{ height: 15, backgroundColor: "transparent" }}
                    />
                    <FormGroup>
                      <TextField
                        variant="outlined"
                        label="Keyword"
                        size="small"
                        value={this.state.keyword}
                        onChange={(e) =>
                          this.setState({ keyword: e.target.value })
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Search
                                onClick={() =>
                                  this.getMailsKeyword(this.state.keyword)
                                }
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormGroup>
                    <Divider
                      style={{ height: 15, backgroundColor: "transparent" }}
                    />
                    {this.state.mails != null &&
                      this.state.mails.map((item, i) => (
                        <Paper style={{ marginBottom: 20 }}>
                          <Box
                            key={i}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            padding={1}
                          >
                            <Typography variant="subtitle1">
                              {item.email}
                              <br />
                              {item?.name}
                              <br />
                              {item?.code}
                            </Typography>
                            <ChevronRight
                              onClick={() =>
                                this.props.history.push(
                                  "/results/" + item.objectId
                                )
                              }
                            />
                          </Box>
                        </Paper>
                      ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default Diet;
