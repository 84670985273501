import React, { Component } from "react";
import moment from "moment";
import { parseURL, appId, masterKey } from "../resources/variables";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  CircularProgress,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  Snackbar,
} from "@material-ui/core/";

export class MedicalHistory extends Component {
  state = {
    name: "",
    age: "",
    occupation: "",
    goals: "",
    listDiseases: listDiseases,
    otherIfTrue: listDiseases[listDiseases.length - 1].specify,
    medicine: "",
    dose: "",
    favorite: [null, null, null],
    dislike: "",
    intolerant: "",
    customDate: "",
    breakfast: "",
    customDate2: "",
    lunch: "",
    //valueSwitch: true,
    customDate3: "",
    dinner: "",
    snacks: "",
    foodWanted: "",
    weight: 0,
    height: 0,
    circumference: 0,
    isThinking: true,
    //
    snack: false,
  };

  componentDidMount() {
    this.userMedicalHistory();
  }

  userMedicalHistory() {
    this.setState({ isThinking: true });
    const medicalHis = this.props.medicalHistory;
    if (medicalHis !== undefined) {
      // Migrating to new hour format
      let valueBreakfast = null;
      let valueLunch = null;
      let valueDinner = null;
      let oldDateBreakfast = moment(medicalHis.habits.breakfastTime)
        .local()
        .format("HH:mm");
      if (oldDateBreakfast === "Invalid date") {
        valueBreakfast = medicalHis.habits.breakfastTime;
      } else {
        valueBreakfast = oldDateBreakfast;
      }
      let oldDateLunch = moment(medicalHis.habits.lunchTime)
        .local()
        .format("HH:mm");
      if (oldDateLunch === "Invalid date") {
        valueLunch = medicalHis.habits.lunchTime;
      } else {
        valueLunch = oldDateLunch;
      }
      let oldDateDinner = moment(medicalHis.habits.dinnerTime)
        .local()
        .format("HH:mm");
      if (oldDateDinner === "Invalid date") {
        valueDinner = medicalHis.habits.dinnerTime;
      } else {
        valueDinner = oldDateDinner;
      }
      // Ends migration
      this.setState({
        name: medicalHis.generalData.name,
        age: medicalHis.generalData.age,
        occupation: medicalHis.generalData.occupation,
        goals: medicalHis.generalData.goals,
        listDiseases: medicalHis.history.disease,
        otherIfTrue:
          medicalHis.history.disease[medicalHis.history.disease.length - 1]
            .specify,
        medicine: medicalHis.history.medicine,
        dose: medicalHis.history.dose,
        favorite: medicalHis.food.favorite,
        dislike: medicalHis.food.dislike,
        intolerant: medicalHis.food.intolerant,
        customDate: valueBreakfast,
        breakfast: medicalHis.habits.breakfast,
        customDate2: valueLunch,
        lunch: medicalHis.habits.lunch,
        valueSwitch: medicalHis.habits.lunchAtWork,
        customDate3: valueDinner,
        dinner: medicalHis.habits.dinner,
        snacks: medicalHis.habits.snacks,
        foodWanted: medicalHis.habits.foodWanted,
        weight: medicalHis.anthropometry.weight,
        height: medicalHis.anthropometry.height,
        circumference: medicalHis.anthropometry.circumference,
      });
    }
    this.setState({ isThinking: false });
  }

  onPresslistDiseases = (item, index) => {
    let listDiseasesUpdate = this.state.listDiseases;
    listDiseasesUpdate[index].checked = !listDiseasesUpdate[index].checked;
    this.setState({ listDiseases: listDiseasesUpdate });
  };

  updateFavorite(text, index) {
    let temp = this.state.favorite;
    temp[index] = text;
    this.setState({ favorite: temp });
  }

  createObject() {
    let temp = this.state.listDiseases;
    temp[temp.length - 1].specify = this.state.otherIfTrue;
    this.setState({ listDiseases: temp });
    const objectMedicalHistory = {
      generalData: {
        name: this.state.name,
        age: this.state.age,
        occupation: this.state.occupation,
        goals: this.state.goals,
      },
      history: {
        disease: this.state.listDiseases,
        medicine: this.state.medicine,
        dose: this.state.dose,
      },
      food: {
        favorite: this.state.favorite,
        dislike: this.state.dislike,
        intolerant: this.state.intolerant,
      },
      habits: {
        breakfastTime: this.state.customDate,
        breakfast: this.state.breakfast,
        lunchTime: this.state.customDate2,
        lunch: this.state.lunch,
        lunchAtWork: this.state.valueSwitch,
        dinnerTime: this.state.customDate3,
        dinner: this.state.dinner,
        snacks: this.state.snacks,
        foodWanted: this.state.foodWanted,
      },
      anthropometry: {
        weight: this.state.weight,
        height: this.state.height,
        circumference: this.state.circumference,
      },
    };
    console.log(objectMedicalHistory);
    this.updateData(objectMedicalHistory);
  }

  updateData(params) {
    axios
      .put(
        parseURL + "users/" + this.props.objectId,
        { medicalHistory: params },
        {
          headers: {
            "X-Parse-Application-Id": appId,
            "X-Parse-Master-Key": masterKey,
          },
        }
      )
      .then((res) => {
        console.log(res);
        this.setState({ snack: true });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleCloseSnack(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snack: false });
  }

  render() {
    return (
      <Box>
        {this.state.isThinking === true ? (
          <CircularProgress />
        ) : (
          <Box>
            <FormGroup>
              <Typography variant="h6">General Data</Typography>
              <TextField
                size="small"
                variant="outlined"
                label="Name"
                style={{ margin: 10 }}
                defaultValue={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                label="Age"
                style={{ margin: 10 }}
                defaultValue={this.state.age}
                onChange={(e) => this.setState({ age: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                label="Occupation"
                style={{ margin: 10 }}
                defaultValue={this.state.occupation}
                onChange={(e) => this.setState({ occupation: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                label="Goals"
                style={{ margin: 10 }}
                defaultValue={this.state.goals}
                onChange={(e) => this.setState({ goals: e.target.value })}
              />
            </FormGroup>
            <Divider style={{ height: 10, backgroundColor: "transparent" }} />
            <Typography variant="h6">Health History</Typography>
            <Grid container>
              {listDiseases.map((item, i) => (
                <Grid item xs={6} key={i}>
                  <FormControlLabel
                    key={i}
                    label={item.title.en}
                    checked={this.state.listDiseases[i].checked}
                    control={<Checkbox />}
                    onChange={() => this.onPresslistDiseases(item, i)}
                  />
                </Grid>
              ))}
            </Grid>
            {this.state.listDiseases[listDiseases.length - 1].checked ===
              true && (
              <FormGroup>
                <TextField
                  size="small"
                  variant="outlined"
                  multiline
                  label="Other"
                  style={{ margin: 10 }}
                  defaultValue={this.state.otherIfTrue}
                  onChange={(e) =>
                    this.setState({ otherIfTrue: e.target.value })
                  }
                />
              </FormGroup>
            )}
            <Divider style={{ height: 10, backgroundColor: "transparent" }} />
            <FormGroup>
              <TextField
                size="small"
                variant="outlined"
                multiline
                label="Do you take any medicine?"
                style={{ margin: 10 }}
                defaultValue={this.state.medicine}
                onChange={(e) => this.setState({ medicine: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                multiline
                label="Dose"
                style={{ margin: 10 }}
                defaultValue={this.state.dose}
                onChange={(e) => this.setState({ dose: e.target.value })}
              />
            </FormGroup>
            <Divider style={{ height: 10, backgroundColor: "transparent" }} />
            <Typography variant="h6">Let's talk about food</Typography>
            <FormGroup>
              <TextField
                size="small"
                variant="outlined"
                label="Food 1"
                style={{ margin: 10 }}
                defaultValue={this.state.favorite[0]}
                onChange={(e) => this.updateFavorite(e.target.value, 0)}
              />
              <TextField
                size="small"
                variant="outlined"
                label="Food 2"
                style={{ margin: 10 }}
                defaultValue={this.state.favorite[1]}
                onChange={(e) => this.updateFavorite(e.target.value, 1)}
              />
              <TextField
                size="small"
                variant="outlined"
                label="Food 3"
                style={{ margin: 10 }}
                defaultValue={this.state.favorite[2]}
                onChange={(e) => this.updateFavorite(e.target.value, 2)}
              />
            </FormGroup>
            <Divider style={{ height: 10, backgroundColor: "transparent" }} />
            <FormGroup>
              <TextField
                size="small"
                variant="outlined"
                multiline
                label="Dislike"
                style={{ margin: 10 }}
                defaultValue={this.state.dislike}
                onChange={(e) => this.setState({ dislike: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                multiline
                label="Intolerant"
                style={{ margin: 10 }}
                defaultValue={this.state.intolerant}
                onChange={(e) => this.setState({ intolerant: e.target.value })}
              />
            </FormGroup>
            <Divider style={{ height: 10, backgroundColor: "transparent" }} />
            <Typography variant="h6">Food and your habits</Typography>
            <FormGroup>
              <TextField
                size="small"
                variant="outlined"
                label="Breakfast time"
                //type="time"
                style={{ margin: 10 }}
                defaultValue={this.state.customDate}
                onChange={(e) => this.setState({ customDate: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                multiline
                label="Breakfast"
                style={{ margin: 10 }}
                defaultValue={this.state.breakfast}
                onChange={(e) => this.setState({ breakfast: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                label="Lunch time"
                //type="time"
                defaultValue={this.state.customDate2}
                style={{ margin: 10 }}
                onChange={(e) => this.setState({ customDate2: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                multiline
                label="Lunch"
                style={{ margin: 10 }}
                defaultValue={this.state.lunch}
                onChange={(e) => this.setState({ lunch: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                label="Dinner time"
                //type="time"
                style={{ margin: 10 }}
                defaultValue={this.state.customDate3}
                onChange={(e) => this.setState({ customDate3: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                multiline
                label="Dinner"
                style={{ margin: 10 }}
                defaultValue={this.state.dinner}
                onChange={(e) => this.setState({ dinner: e.target.value })}
              />
            </FormGroup>
            <Divider style={{ height: 10, backgroundColor: "transparent" }} />
            <FormGroup>
              <TextField
                size="small"
                variant="outlined"
                //multiline
                label="Snacks"
                style={{ margin: 10 }}
                defaultValue={this.state.snacks}
                onChange={(e) => this.setState({ snacks: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                multiline
                label="Like include"
                style={{ margin: 10 }}
                defaultValue={this.state.foodWanted}
                onChange={(e) => this.setState({ foodWanted: e.target.value })}
              />
            </FormGroup>
            <Divider style={{ height: 10, backgroundColor: "transparent" }} />
            <Typography variant="h6">Anthropometry</Typography>
            <FormGroup>
              <TextField
                size="small"
                variant="outlined"
                multiline
                label="Weight"
                style={{ margin: 10 }}
                defaultValue={this.state.weight}
                onChange={(e) => this.setState({ weight: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                multiline
                label="Height"
                style={{ margin: 10 }}
                defaultValue={this.state.height}
                onChange={(e) => this.setState({ height: e.target.value })}
              />
              <TextField
                size="small"
                variant="outlined"
                multiline
                label="Waist circumerence"
                style={{ margin: 10 }}
                defaultValue={this.state.circumference}
                onChange={(e) =>
                  this.setState({ circumference: e.target.value })
                }
              />
            </FormGroup>
            <Button
              onClick={() => this.createObject()}
              color="primary"
              variant="contained"
              fullWidth
            >
              Update Data
            </Button>
          </Box>
        )}
        <Snackbar
          open={this.state.snack}
          autoHideDuration={4000}
          onClose={(e, r) => this.handleCloseSnack(e, r)}
        >
          <Alert
            onClose={(e, r) => this.handleCloseSnack(e, r)}
            severity="success"
          >
            This is a success message!
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}
export default MedicalHistory;

const listDiseases = [
  {
    title: { en: "Cancer", es: "Cáncer" },
    checked: false,
  },
  {
    title: { en: "Gastritis/ colitis", es: "Gastritis/ colitis" },
    checked: false,
  },
  {
    title: { en: "Constipation", es: "Estreñimiento" },
    checked: false,
  },
  {
    title: { en: "Diabetes", es: "Diabetes" },
    checked: false,
  },
  {
    title: {
      en: "Polycustic ovary syndrome",
      es: "Síndrome de ovario poliquístico",
    },
    checked: false,
  },
  {
    title: { en: "High cholesterol", es: "Colesterol elevado" },
    checked: false,
  },
  {
    title: { en: "High triglycerides", es: "Triglicéridos elevados" },
    checked: false,
  },
  {
    title: { en: "Other", es: "Otra" },
    checked: false,
    specify: "",
  },
];
