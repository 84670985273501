const isProduction = true;

export const appId = "aVcbcdaMSITLDSmqDLKCrRr3sFRefjUpPW8p8qmJ";
// export const restApi = "fgf97b986fe978b5dsgnbaefo6hgs97n5daf909";
export const masterKey = "CyEvKEjFTlCxDWTpsvb7gqgeV6Z9jKuNThsZ2Stj";

export const parseURL = isProduction
  ? "https://v2.api.nutrigeek.fit/"
  : "http://206.189.64.83:1687/";
export const imgixURL = isProduction
  ? "https://nutrigeek.imgix.net/"
  : "https://nutridev.imgix.net/";

export const rowsPerPage = [5, 10, 50, 100];

export const typeForTask = [
  "distanceWalkingRunning",
  "activeEnergy",
  "stepCount",
  "flightsClimbed",
  "workout",
  "appleExerciseTime",
];
