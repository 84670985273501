import React, { Component } from "react";
import { parseURL, appId, rowsPerPage } from "../resources/variables";
import axios from "axios";
import { colors } from "../styles/styles";
import { PlayCircleFilled, Cancel, Search } from "@material-ui/icons";
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core/";

export class Equivalents extends Component {
  state = {
    isThinking: true,
    equivalents: null,
    equivalentCategory: null,
    equivalentMeasure: null,
    keyword: "",
    page: 0,
    rowsPerPage: 10,
    openDialog: false,
    currentDialog: "newEquivalent",
    eqIndex: 0,
    dialogAlert: false,
    //Add
    category: "",
    titleEn: "",
    titleEs: "",
    quantity: "",
    measurement: "",
    //Edit
    editCategory: "",
    editTitleEn: "",
    editTitleEs: "",
    editQuantity: "",
    editMeasurement: "",
  };

  componentDidMount() {
    this.arrayholder = [];
    this.getEquivalents();
    this.getEquivalentCategory();
    this.getEquivalentMeasurement();
  }

  getEquivalents() {
    axios
      .get(parseURL + "classes/Equivalent?order=-createdAt&limit=1000", {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        this.setState({ equivalents: res.data.results });
        this.arrayholder = res.data.results;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getEquivalentCategory() {
    axios
      .get(parseURL + "classes/EquivalentCategory", {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        this.setState({
          equivalentCategory: res.data.results,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getEquivalentMeasurement() {
    axios
      .get(parseURL + "classes/EquivalentMeasurement", {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((res) => {
        console.log(res.data.results);
        this.setState({ equivalentMeasure: res.data.results });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  searchFilter(text) {
    this.setState({ keyword: text });
    let newData = this.arrayholder.filter((element) => {
      let itemData = `${element.name.en.toUpperCase()}${element.name.es.toUpperCase()}`;
      let textData = text.toUpperCase();
      itemData = itemData
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trimRight();
      textData = textData
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trimRight();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({ equivalents: newData });
  }

  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(e) {
    let numberValue = Number(e);
    this.setState({ rowsPerPage: numberValue, page: 0 });
  }

  postNewEquivalent() {
    let quantityNum = Number(this.state.quantity);
    let params = {
      quantity: quantityNum,
      title: JSON.stringify({
        // DEPRECATED, REMOVE ONCE APP IS UPDATED
        en: this.state.titleEn,
        es: this.state.titleEs,
      }),
      name: {
        en: this.state.titleEn,
        es: this.state.titleEs,
      },
      measurement: {
        __type: "Pointer",
        className: "EquivalentMeasurement",
        objectId: this.state.measurement,
      },
      category: {
        __type: "Pointer",
        className: "EquivalentCategory",
        objectId: this.state.category,
      },
    };
    axios
      .post(parseURL + "classes/Equivalent", params, {
        headers: {
          "X-Parse-Application-Id": appId,
        },
      })
      .then((response) => {
        console.log(response);
        this.setState({ openDialog: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ openDialog: false });
      })
      .finally(() => {
        this.setState({ isThinking: false, currentDialog: "newEquivalent" });
        this.getEquivalents();
      });
  }

  putEditEquivalent() {
    let quantityNum = Number(this.state.editQuantity);
    let params = {
      quantity: quantityNum,
      title: JSON.stringify({
        // DEPRECATED, REMOVE ONCE APP IS UPDATED
        en: this.state.editTitleEn,
        es: this.state.editTitleEs,
      }),
      name: {
        en: this.state.editTitleEn,
        es: this.state.editTitleEs,
      },
      measurement: {
        __type: "Pointer",
        className: "EquivalentMeasurement",
        objectId: this.state.editMeasurement,
      },
      category: {
        __type: "Pointer",
        className: "EquivalentCategory",
        objectId: this.state.editCategory,
      },
    };
    axios
      .put(
        parseURL +
          "classes/Equivalent/" +
          this.state.equivalents[this.state.eqIndex].objectId,
        params,
        {
          headers: {
            "X-Parse-Application-Id": appId,
          },
        }
      )
      .then((response) => {
        console.log(response);
        this.setState({ openDialog: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ openDialog: false });
      })
      .finally(() => {
        this.setState({ isThinking: false, currentDialog: "newEquivalent" });
        this.getEquivalents();
      });
  }

  deleteEquivalent() {
    axios
      .delete(
        parseURL +
          "classes/Equivalent/" +
          this.state.equivalents[this.state.eqIndex].objectId,
        {
          headers: {
            "X-Parse-Application-Id": appId,
          },
        }
      )
      .then((response) => {
        console.log(response);
        this.setState({
          openDialog: false,
          eqIndex: 0,
          dialogAlert: false,
          currentDialog: "newEquivalent",
        });
        this.getEquivalents();
      })
      .catch((error) => {
        console.log(error);
        this.setState({ openDialog: false });
      })
      .finally(() => {
        this.setState({ isThinking: false });
      });
  }

  renderDialog() {
    let currentDialog = this.state.currentDialog;
    return (
      this.state.equivalentCategory != null &&
      this.state.equivalentMeasure != null && (
        <DialogContent>
          <TextField
            fullWidth
            select
            variant="outlined"
            size="small"
            label="Category"
            defaultValue={
              currentDialog === "newEquivalent"
                ? null
                : this.state.equivalents[this.state.eqIndex].category.objectId
            }
            onChange={(e) =>
              currentDialog === "newEquivalent"
                ? this.setState({ category: e.target.value })
                : this.setState({ editCategory: e.target.value })
            }
          >
            {this.state.equivalentCategory.map((e, i) => (
              <MenuItem key={e.objectId} value={e.objectId}>
                {e.title.en}
              </MenuItem>
            ))}
          </TextField>
          <Divider style={{ height: 15, backgroundColor: "transparent" }} />
          <Box display="flex" justifyContent="space-between">
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Title 🇺🇸"
              defaultValue={
                currentDialog === "newEquivalent"
                  ? null
                  : this.state.equivalents[this.state.eqIndex].name.en
              }
              onChange={(e) =>
                currentDialog === "newEquivalent"
                  ? this.setState({ titleEn: e.target.value })
                  : this.setState({ editTitleEn: e.target.value })
              }
            />
            <Divider style={{ width: 10, backgroundColor: "transparent" }} />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Title 🇲🇽"
              defaultValue={
                currentDialog === "newEquivalent"
                  ? null
                  : this.state.equivalents[this.state.eqIndex].name.es
              }
              onChange={(e) =>
                currentDialog === "newEquivalent"
                  ? this.setState({ titleEs: e.target.value })
                  : this.setState({ editTitleEs: e.target.value })
              }
            />
          </Box>
          <Divider style={{ height: 15, backgroundColor: "transparent" }} />
          <TextField
            fullWidth
            type="number"
            variant="outlined"
            size="small"
            label="Quantity"
            defaultValue={
              currentDialog === "newEquivalent"
                ? null
                : this.state.equivalents[this.state.eqIndex].quantity
            }
            onChange={(e) =>
              currentDialog === "newEquivalent"
                ? this.setState({ quantity: e.target.value })
                : this.setState({ editQuantity: e.target.value })
            }
          />
          <Divider style={{ height: 15, backgroundColor: "transparent" }} />
          <TextField
            fullWidth
            select
            variant="outlined"
            size="small"
            label="Measurement"
            defaultValue={
              currentDialog === "newEquivalent"
                ? null
                : this.state.equivalents[this.state.eqIndex].measurement
                    .objectId
            }
            onChange={(e) =>
              currentDialog === "newEquivalent"
                ? this.setState({ measurement: e.target.value })
                : this.setState({ editMeasurement: e.target.value })
            }
          >
            {this.state.equivalentMeasure.map((e, i) => (
              <MenuItem key={e.objectId} value={e.objectId}>
                {e.name.en}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
      )
    );
  }

  render() {
    return (
      <Box style={{ paddingLeft: 90 }}>
        <Box
          display="flex"
          justifyContent="space-around"
          style={{ backgroundColor: "blue", padding: 10 }}
        >
          <Button
            variant="contained"
            onClick={() => this.props.history.push("/dashboard")}
          >
            Atras
          </Button>
          <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
          <Button
            variant="contained"
            onClick={() =>
              this.setState({
                currentDialog: "newEquivalent",
                openDialog: true,
              })
            }
          >
            Add Equivalent
          </Button>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography
            style={{
              fontWeight: "600",
              fontSize: 12,
              textTransform: "uppercase",
              marginLeft: 10,
              marginTop: 10,
            }}
          >
            Equivalents
          </Typography>
          <Divider style={{ backgroundColor: "transparent", flex: 1 }} />
          <TextField
            style={{ marginTop: 10, marginRight: 10 }}
            size="small"
            variant="outlined"
            label="Keyword"
            value={this.state.keyword}
            onChange={(text) => this.searchFilter(text.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {this.state.equivalents !== null && (
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Title [EN]</TableCell>
                  <TableCell>Title [ES]</TableCell>
                  <TableCell>Portion</TableCell>
                  <TableCell> Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.equivalents
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((item, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {item.name.en}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.name.es}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.quantity}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <IconButton
                          onClick={() =>
                            this.setState(
                              {
                                eqIndex:
                                  this.state.page === 0
                                    ? i
                                    : this.state.rowsPerPage * this.state.page +
                                      i,
                                currentDialog: "editEquivalent",
                                editCategory: item.category.objectId,
                                editTitleEn: item.name.en,
                                editTitleEs: item.name.es,
                                editQuantity: item.quantity,
                                editMeasurement: item.measurement.objectId,
                                openDialog: true,
                              },
                              console.log(item.objectId)
                            )
                          }
                        >
                          <PlayCircleFilled />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={rowsPerPage}
              component="div"
              count={this.state.equivalents.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={(e, n) => this.handleChangePage(e, n)}
              onChangeRowsPerPage={(e) =>
                this.handleChangeRowsPerPage(e.target.value)
              }
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            />
          </TableContainer>
        )}
        <Dialog open={this.state.openDialog} fullWidth={true} maxWidth={"sm"}>
          <DialogTitle
            disableTypography
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>Add Equivalent</Typography>
            <IconButton
              onClick={() =>
                this.setState({
                  openDialog: false,
                  currentDialog: "newEquivalent",
                })
              }
            >
              <Cancel />
            </IconButton>
          </DialogTitle>
          {this.renderDialog()}
          <DialogActions
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{
                color:
                  this.state.currentDialog === "newEquivalent"
                    ? colors.muted
                    : "red",
              }}
              disabled={
                this.state.currentDialog === "newEquivalent" ? true : false
              }
              onClick={() => this.setState({ dialogAlert: true })}
            >
              Delete
            </Button>
            <Button
              color="primary"
              onClick={() =>
                this.state.currentDialog === "newEquivalent"
                  ? this.postNewEquivalent()
                  : this.putEditEquivalent()
              }
            >
              Accept
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.dialogAlert}>
          <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
          <DialogContent>
            <Typography>Are you sure to delete it?</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogAlert: false })}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.deleteEquivalent()}
              color="primary"
              autoFocus
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default Equivalents;
