import React, { Component } from "react";
import axios from "axios";
import { parseURL, appId } from "../resources/variables";
import {
  Box,
  Grid,
  FormGroup,
  TextField,
  InputAdornment,
  Button,
  Divider,
} from "@material-ui/core/";

import { login } from "../resources/Auth";

class Login extends Component {
  state = {
    username: null,
    password: null,
  };

  componentDidMount() {}

  loginUser() {
    axios
      .get(
        parseURL +
          "login?username=" +
          this.state.username +
          "&password=" +
          this.state.password,
        {
          headers: {
            "X-Parse-Application-Id": appId,
          },
        }
      )
      .then((res) => {
        console.log(res);
        login(res.data.sessionToken);
        this.props.history.push("/");
      })
      .catch((err) => {
        //this.setState({ isThinking: false, errorLogin: true });
        console.log(err.response.data);
      });
  }
  
  render() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ paddingLeft: 90 }}
        height="100vh"
      >
        <Grid
          xs={3}
          style={{
            paddingTop: 20,
            paddingLeft: 10,
            paddingRight: 10,
            //height: 300,
          }}
        >
          <FormGroup>
            <TextField
              size="small"
              variant="outlined"
              label="Nombre de Usuario"
              value={this.state.username}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {/* <AccountCircle /> */}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => this.setState({ username: e.target.value })}
            />
            <Divider style={{ backgroundColor: "transparent", height: 10 }} />
            <TextField
              size="small"
              variant="outlined"
              label="Contraseña"
              value={this.state.password}
              type="password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {/* <Lock /> */}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <Divider style={{ backgroundColor: "transparent", height: 10 }} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.loginUser()}
            >
              Entrar
            </Button>
          </FormGroup>
        </Grid>
      </Box>
    );
  }
}

export default Login;
