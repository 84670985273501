import React from "react";
import {
  BrowserRouter as Router,
  NavLink,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Ikon from "./resources/icon";
import { Box, Drawer, Typography } from "@material-ui/core/";
import { colors } from "./styles/styles";

//import Text from "./components/Testing";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";

import Login from "./screens/Login";
import Categories from "./screens/Categories";
import Challenges from "./screens/Challenges";
import Dashboard from "./screens/Dashboard";
import Diet from "./screens/Diet";
import Equivalents from "./screens/Equivalents";
import Meals from "./screens/Meals";
import Results from "./screens/Results";
import Tasks from "./screens/Tasks";

class App extends React.Component {
  state = {
    menu: menu,
  };

  changeColor(index) {
    let tempMenu = this.state.menu;
    for (let i = 0; i < tempMenu.length; i++) {
      tempMenu[i].selected = false;
    }
    tempMenu[index].selected = true;
    this.setState({ menu: tempMenu });
  }

  render() {
    return (
      <Router>
        <Box>
          <Drawer
            variant="permanent"
            style={{ backgroundColor: "blue" }}
            classes={{ paper: { backgroundColor: "red" } }}
          >
            <Ikon
              icon="Logo"
              size={50}
              style={{
                alignSelf: "center",
                paddingTop: 8,
                paddingBottom: 8,
                color: colors.primaryLight,
              }}
            />
            {/* <Text style="changos" text="brassh" /> */}
            {this.state.menu.map((item, i) => (
              <Box
                key={i}
                component={NavLink}
                to={item.path}
                onClick={() => this.changeColor(i)}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={styles.menuBox}
                activeStyle={styles.menuBoxActive}
              >
                <Ikon icon={item.icon} size={25} style={{ paddingBottom: 5 }} />
                <Typography style={styles.menuText}>{item.title}</Typography>
              </Box>
            ))}
          </Drawer>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <PublicRoute path="/login" exact component={Login} />
            <PrivateRoute path="/dashboard" exact component={Dashboard} />
            <PrivateRoute path="/categories" exact component={Categories} />
            <PrivateRoute path="/challenges" exact component={Challenges} />
            <PrivateRoute path="/diet" exact component={Diet} />
            <PrivateRoute path="/equivalents" exact component={Equivalents} />
            <PrivateRoute path="/meals" exact component={Meals} />
            <PrivateRoute path="/results/:id" exact component={Results} />
            <PrivateRoute path="/tasks" exact component={Tasks} />
          </Switch>
        </Box>
      </Router>
    );
  }
}

export default App;

const styles = {
  menuBox: {
    height: 60,
    width: 80,
    borderRadius: 5,
    margin: 5,
    textDecoration: "none",
    backgroundColor: colors.bg[200],
    color: colors.bg[600],
  },
  menuBoxActive: {
    height: 60,
    width: 80,
    borderRadius: 5,
    margin: 5,
    textDecoration: "none",
    backgroundColor: colors.primary,
    color: colors.bg[200],
  },
  menuText: {
    fontSize: 10,
    fontWeight: "600",
    textTransform: "uppercase",
  },
};

const menu = [
  {
    title: "Dashboard",
    icon: "Progress",
    path: "/dashboard",
    selected: false,
  },
  {
    title: "Categories",
    icon: "Progress",
    path: "/categories",
    selected: false,
  },
  {
    title: "Challenges",
    icon: "Progress",
    path: "/challenges",
    selected: false,
  },
  {
    title: "Diet",
    icon: "Dinner",
    path: "/diet",
    selected: false,
  },
  {
    title: "Equivalents",
    icon: "Measuring-Cup",
    path: "/equivalents",
    selected: false,
  },
  {
    title: "Dishes",
    icon: "Cooking",
    path: "/meals",
    selected: false,
  },
  {
    title: "Tasks",
    icon: "Progress",
    path: "/tasks",
    selected: false,
  },
];
