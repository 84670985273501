// Diet

export const dietStructure_3 = [
  {
    id: "breakfast",
    type: "group",
    time: "9:00",
    children: [
      {
        id: "optionOne",
        type: "collection",
        title: "Opción  1",
        children: [],
      },
      {
        id: "optionTwo",
        type: "collection",
        title: "Opción  2",
        children: [],
      },
      {
        id: "optionTree",
        type: "collection",
        title: "Opción  3",
        children: [],
      },
    ],
  },
  {
    id: "earlySnack", // breakfast, earlySnack, lunch, lateSnack, dinner, workoutSnack
    type: "group",
    time: "10:00",
    children: [
      {
        id: "daily",
        type: "collection",
        title: "Diario",
        children: [],
      },
    ],
  },
  {
    id: "lunch", // breakfast, earlySnack, lunch, lateSnack, dinner, workoutSnack
    type: "group",
    time: "9:00",
    children: [
      {
        id: "optionOne",
        type: "collection",
        title: "Opción  1",
        children: [],
      },
      {
        id: "optionTwo",
        type: "collection",
        title: "Opción  2",
        children: [],
      },
      {
        id: "optionTree",
        type: "collection",
        title: "Opción  3",
        children: [],
      },
    ],
  },
  {
    id: "lateSnack", // breakfast, earlySnack, lunch, lateSnack, dinner, workoutSnack
    type: "group",
    time: "17:00",
    children: [
      {
        id: "daily",
        type: "collection",
        title: "Diario",
        children: [],
      },
    ],
  },
  {
    id: "dinner", // breakfast, earlySnack, lunch, lateSnack, dinner, workoutSnack
    type: "group",
    time: "20:00",
    children: [
      {
        id: "optionOne",
        type: "collection",
        title: "Opción  1",
        children: [],
      },
      {
        id: "optionTwo",
        type: "collection",
        title: "Opción  2",
        children: [],
      },
      {
        id: "optionTree",
        type: "collection",
        title: "Opción  3",
        children: [],
      },
    ],
  },
];

export const dietStructure_7 = [
  {
    id: "breakfast",
    type: "group",
    time: "9:00",
    children: [
      {
        id: "monday",
        type: "collection",
        title: "Lunes",
        children: [],
      },
      {
        id: "tuesday",
        type: "collection",
        title: "Martes",
        children: [],
      },
      {
        id: "wednesday",
        type: "collection",
        title: "Miércoles",
        children: [],
      },
      {
        id: "thursday",
        type: "collection",
        title: "Jueves",
        children: [],
      },
      {
        id: "friday",
        type: "collection",
        title: "Viernes",
        children: [],
      },
      {
        id: "saturday",
        type: "collection",
        title: "Sábado",
        children: [],
      },
      {
        id: "sunday",
        type: "collection",
        title: "Domingo",
        children: [],
      },
    ],
  },
  {
    id: "earlySnack", // breakfast, earlySnack, lunch, lateSnack, dinner, workoutSnack
    type: "group",
    time: "10:00",
    children: [
      {
        id: "daily",
        type: "collection",
        title: "Diario",
        children: [],
      },
    ],
  },
  {
    id: "lunch", // breakfast, earlySnack, lunch, lateSnack, dinner, workoutSnack
    type: "group",
    time: "9:00",
    children: [
      {
        id: "monday",
        type: "collection",
        title: "Lunes",
        children: [],
      },
      {
        id: "tuesday",
        type: "collection",
        title: "Martes",
        children: [],
      },
      {
        id: "wednesday",
        type: "collection",
        title: "Miércoles",
        children: [],
      },
      {
        id: "thursday",
        type: "collection",
        title: "Jueves",
        children: [],
      },
      {
        id: "friday",
        type: "collection",
        title: "Viernes",
        children: [],
      },
      {
        id: "saturday",
        type: "collection",
        title: "Sábado",
        children: [],
      },
      {
        id: "sunday",
        type: "collection",
        title: "Domingo",
        children: [],
      },
    ],
  },
  {
    id: "lateSnack", // breakfast, earlySnack, lunch, lateSnack, dinner, workoutSnack
    type: "group",
    time: "17:00",
    children: [
      {
        id: "daily",
        type: "collection",
        title: "Diario",
        children: [],
      },
    ],
  },
  {
    id: "dinner", // breakfast, earlySnack, lunch, lateSnack, dinner, workoutSnack
    type: "group",
    time: "20:00",
    children: [
      {
        id: "monday",
        type: "collection",
        title: "Lunes",
        children: [],
      },
      {
        id: "tuesday",
        type: "collection",
        title: "Martes",
        children: [],
      },
      {
        id: "wednesday",
        type: "collection",
        title: "Miércoles",
        children: [],
      },
      {
        id: "thursday",
        type: "collection",
        title: "Jueves",
        children: [],
      },
      {
        id: "friday",
        type: "collection",
        title: "Viernes",
        children: [],
      },
      {
        id: "saturday",
        type: "collection",
        title: "Sábado",
        children: [],
      },
      {
        id: "sunday",
        type: "collection",
        title: "Domingo",
        children: [],
      },
    ],
  },
];

export let group = {
  id: "breakfast", // breakfast, earlySnack, lunch, lateSnack, dinner, workoutSnack
  type: "group",
  time: "9:00",
  children: [],
};

export const collection = {
  id: "", // Random String
  type: "collection",
  title: "Sabado", // Día de la semana, opción, diario u otro.
  children: [],
};

export const item = {
  id: null, // Random String for sortable
  type: "item",
  name: null,
  description: null,
  isPortion: false,
  hasRecipe: false,
  isVeggieOption: false,
  calories: 0,
  portions: {
    dairy: { _id: "2GhQ5T4MfT", value: 0 },
    legumes: { _id: "a9XtMSpa7M", value: 0 },
    calorieFree: { _id: "GHAUm8i0KC", value: 0 },
    oilsAndFats: { _id: "8J0JiF6C9j", value: 0 },
    protein: { _id: "naq38iCrpg", value: 0 },
    cereals: { _id: "FTXvmJR0iY", value: 0 },
    fruits: { _id: "rvDflv32V7", value: 0 },
    vegetables: { _id: "X3EU29CGbd", value: 0 },
  },
  mealTime: [
    { id: "breakfast", checked: false },
    { id: "lunch", checked: false },
    { id: "dinner", checked: false },
    { id: "snack", checked: false },
    { id: "addOn", checked: false },
  ],
};
